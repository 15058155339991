.flow{
  &-alert{
    border:3px solid $color-red;
    background: $color-red-light;
    &-contents{
      padding:30px;
    }
  }
  &-cta{
    &--white{
      border: 1px solid #707070;
      background: $color-white;
    }

    &-arrow {
      display: inline-block;
      position: relative;
      top: -10px;
      margin-right: 10px;
    }
    
    &-contents{
      padding: 30px;
      display: flex;
      @include view-at(sp){
        flex-direction: column;
      }
    }
    &-number {
      font-size: rem(37px);
      font-weight: $bold;
      margin-top: 1px;
      letter-spacing: 0.09em;
      margin-left: 1px;
      color:$color-black;
  
      @include view-at(tab) {
        font-size: rem(24px);
      }
    }
  
    &-text {
      font-size: rem(16px);
      font-weight: $medium;
      text-align: right;
      padding-right: 0.5rem;
  
      @include view-at(tab) {
        text-align: center;
      }
    }
    &-mail {
      background-color: $color-navy;
      display: flex;
      width: 100%;
      height: 84px;
      color: $color-white;
      font-size: rem(22px);
      text-align: center;
      align-items: center;
      justify-content: center;
      transition: all 0.3s;
      border: 1px solid $color-navy;

      @include view-at(tab) {
        margin-left: 0;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      &:hover {
        background-color: $color-white;
        color: $color-navy;
        opacity: 1;

        &::before {
          background-image: url(/images/common/icon-mail-inverse.png);
        }
      }

      &::before {
        content: '';
        display: inline-block;
        width: 29px;
        height: 20px;
        margin-right: 10px;
        background: transparent url(/images/common/icon-mail.png) center center no-repeat;
        background-size: contain;
      }
    }
    &-reserve {
      background-color: $color-red-dark;
      display: flex;
      width: 100%;
      height: 84px;
      color: $color-white;
      font-size: rem(22px);
      text-align: center;
      align-items: center;
      justify-content: center;
      margin-left: 20px;
      transition: all 0.3s;
      border: 1px solid $color-red-dark;

      @include view-at(tab) {
        margin-left: 0;
      }

      &:hover {
        background-color: $color-white;
        color: $color-red-dark;
        opacity: 1;

        &::before {
          background-image: url(/images/common/icon-reserve-inverse.png);
        }
      }

      &::before {
        content: '';
        display: inline-block;
        width: 31px;
        height: 31px;
        margin-right: 10px;
        background: transparent url(/images/common/icon-reserve.png) center center no-repeat;
        background-size: contain;
      }
    }
  }

  &-chart{
    display: flex;
    justify-content: space-between;
    margin: 0 -30px;

    @include view-at(tab){
      justify-content: center;
      flex-wrap: wrap;
      margin: -15px;
    }

    @include view-at(sp){
      margin: -10px;
    }

    &-item{
      padding: 0 30px;
      width: 100%;
      position: relative;

      @include view-at(tab){
        width:33.3333%;
        padding: 15px;
      }

      @include view-at(sp){
        width: 50%;
        padding: 10px;
      }

      &-step{
        background: $color-blue-light;
        text-align: center;
        padding: 20px 0;
        height: 100%;

        &--title{
          color:$color-blue-dark;
          font-size:18px;

          @include view-at(sp){
            font-size: rem(16px);
          }
        }
        &--number{
          color:$color-blue-dark;
          font-size: 40px;
          line-height: 1;
          font-weight: $bold;
          margin: 5px 0 15px;

          @include view-at(sp){
            font-size: rem(30px);
          }
        }
        &--text{
          font-size: 20px;
          font-weight: $bold;
          margin-bottom: 0;
          line-height: 1.2;

          span {
            line-height: 1;
          }
        }
        &-finish{
          background: linear-gradient(90deg, #008ddd 0%, #004d8f 100%);
          .flow-chart-item-step--title,
          .flow-chart-item-step--number,
          .flow-chart-item-step--text{
            color:$color-white;
          }
        }
      }
      &::before{
        content:'';
        position: absolute;
        top:50%;
        right:-10px;
        width:60px;
        height: 5px;
        background: $color-blue;
        border-radius: 2px;
        @include view-at(sp){
          width:30px;
          right: 0;
        }
      }
      &::after{
        content:'';
        position: absolute;
        top:calc(50% - 7.5px);
        right: -10px;
        width: 20px;
        height: 20px;
        border-top: 5px solid $color-blue;
        border-right: 5px solid $color-blue;
        transform: rotate(45deg);
        border-radius: 2px;
        @include view-at(sp){
          right:0;
        }
      }
      &:last-child{
        &::before,&::after{
          content:none;
        }
      } 
      
    }
  }

  &-panel{
    background: $color-blue-light;
    &-finish{
      background: linear-gradient(90deg, #008ddd 0%, #004d8f 100%);
      .flow-panel-section{
        border: 1px solid $color-white;
        
        &-title{
          background: #104175;
        }
        &-area{
          border-left:none;
          margin-left: 0;
          color:$color-white;
          &::before,&::after{
            content: none;
          }
        }
      }
    }
    &-contents{
      padding: 50px 65px 0;
      @include view-at(sp){
        padding:20px;
      }
    }
    &-section{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      &-step{
        flex-basis: 160px;
        flex-shrink: 0;
        align-self: stretch;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background: $color-white;

        @include view-at(sp){
          flex-basis: 66px;
          padding: 5px;
        }

        &-circle{
          background: $color-blue-light;
          border-radius: 50%;
          color:$color-blue-dark;

          @include view-at(sp){
            width: 100%;
          }
          span{
            font-size:18px;

            @include view-at(sp){
              font-size: 14px;
            }
          }
          p{
            font-size: 39px;
            font-weight: $bold;
            margin: 5px 0 0;

            @include view-at(sp){
              font-size: 30px;
              margin: 0;
              line-height: 40px;
            }
          }
        }
      }
      &-title{
        width:100%;
        padding: 20px 30px;
        background: linear-gradient(90deg, #008ddd 0%, #004d8f 100%);
        color:$color-white;
        font-size: rem(26px);
        line-height: rem(48px);
        font-weight: $bold;

        @include view-at(sp){
          font-size: rem(20px);
          line-height: 1.5;
        }
      }
      &-area{
        border-left:5px solid $color-blue;
        margin-left: 80px;
        padding: 35px 40px 50px;
        position: relative;
        @include view-at(sp){
          padding: 20px 0 0;
          margin-left: 0px;
          border-left:none;
        }
        &::before{
          content:'';
          position: absolute;
          bottom:-35px;
          left:-5px;
          background: $color-blue;
          width:5px;
          height: 40px;
          @include view-at(sp){
            left: 0;
            right:0;
            margin: 0 auto;
            bottom:-50px;
          }
        }
        &::after{
          content:'';
          position: absolute;
          bottom:-35px;
          left: -12px;
          width:20px;
          height: 20px;
          display: inline-block;
          border-right:5px solid $color-blue;
          border-bottom:5px solid $color-blue;
          transform: rotate(45deg);
          @include view-at(sp){
            left: 0;
            right:0;
            margin: 0 auto;
            bottom:-50px;
          }
        }
        &-h{
          color:$color-blue-dark;
          font-weight: $bold;
          line-height: 1.5;
          font-size:20px;
          margin-bottom: 15px;
        }
        &-list{
          >li{
            padding-left:1.2rem;
            line-height: rem(28px);
            // margin-bottom: 10px;
            position: relative;
            &:last-child{
              margin-bottom: 0;
            }
            &::before{
              position: absolute;
              top:0;
              left: 0;
              content:'●';
              display: inline-block;
              color:$color-blue-dark;
            }
          }
        }
      }
    }
  }
  &-cta-contact{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 35px 30px;
    border: 0.5px solid #707070;
    &--blue{
      background: $color-blue-light;
    }
    &-title{
      font-size: rem(20px);
      line-height: rem(24px);
      letter-spacing: 0.05em;
      font-weight: $bold;

      @include view-at(tab) {
        margin-bottom: 5px;
        text-align: left;
      }
    }
    &-tel{
      display: flex;
      align-items: center;
      @include view-at(sp){
        flex-direction: column;
      }
    }
    &-number {
      font-size: rem(43px);
      font-weight: $bold;
      margin-top: 1px;
      letter-spacing: 0.09em;
      margin-left: 1px;

      @include view-at(tab) {
        font-size: rem(24px);
      }
    }
    &-text {
      padding-left:20px;
      line-height: 1.5;
      font-size: rem(13px);
      font-weight: $medium;
      text-align: left;
      padding-right: 0.5rem;

      @include view-at(tab) {
        text-align: center;
      }
    }
  }
}