.en{
    &-panel{
        margin-bottom: 40px;

        &-contents{
            background: url(/images/en/panel-link.png);
            background-size: cover;
            background-position: top left;
            background-repeat: no-repeat;
            padding: 35px 45px;
            border:1px solid $color-gray;

            @include view-at(sp){
                padding: 30px;
            }

        }

        &-h{
            font-size: rem(22px);
            line-height: rem(33px);
            font-weight: $bold;
            margin-bottom: 20px;
            color:$color-blue-dark;
        }
    }
}