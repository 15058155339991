.saif{

  &-accent-color{
    color:$color-blue-dark;
  }

  &-border-bottom{
    border-bottom:3px solid $color-blue;
    padding-bottom:5px;
    line-height: 1.6;
  }
}