.succession {
  &-example {
    display: flex;
    align-items: center;
    padding-bottom: 40px;
    border-bottom: 1px solid #707070;

    @include view-at(sp) {
      display: block;
    }


    &-title {
      font-size: rem(22px);
      font-weight: $bold;
      color: $color-blue-dark;
      line-height: 37px;
      flex-shrink: 0;
      padding: 20px;
      width: 300px;

      @include view-at(sp) {
        width: 100%;
        padding: 20px 0;
      }
    }

    &-list {
      list-style: none;

      li {
        line-height: 1.8;
        margin: 5px 0;
        font-size: rem(15px);
      }
    }

    &-flow {
      padding: 40px 0 10px;
    }
  }
}