.feature{
    &-title{
        letter-spacing: .3rem;
        font-size: 24px;
        font-weight: $bold;
        margin-bottom: 25px;
        line-height: 1.7;
    }

    &-panel{
        background:linear-gradient(transparent 50%, #e5f5ff 50%);

        &-header{
            display: flex;
            align-items: center;
            padding: 20px 30px;
            background: linear-gradient(90deg, #008ddd 0%, #004d8f 100%);

            @include view-at(tab){
                padding: 10px 15px;
            }

            @include view-at(sp){
                flex-direction: column;
                padding: 5px;
            }
        }

        &-contents{
            color:$color-white;
        }

    }


    &-list{
        display: flex;
        flex-wrap: wrap;
        margin: -15px;

        &-item{
            width:calc(100% / 3);
            padding: 15px;

            @include view-at(tab){
                width: 50%;
            }

            @include view-at(sp){
                width: 100%;
            }

            a{
                height: 100%;
                color:$color-black;
                font-weight: $bold;
                text-align: left;
                line-height: 1.6;
                border-radius: 50px;
                padding: 20px 35px;

                &::after{
                    position: absolute;
                    top:calc(50% - 4px);
                    right:20px;
                    content:'';
                    width: 8px;
                    height: 8px;
                    display: inline-block;
                    border-top:2px solid $color-blue;
                    border-right:2px solid $color-blue;
                    transform: rotate(45deg);
                }

                &:hover{
                    &::after{
                        border-color:$color-white;

                    }
                }

                &[aria-selected="true"]{
                    background: $color-blue;
                    color:$color-white;
                    pointer-events: none;

                    &::after{
                        border-color:$color-white;
                    }
                }

            }

            &--button{
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
            }
        }
    }

    &-episode {
        &-contents {
            &[aria-hidden="true"] {
                display: none;
            }
            &[aria-hidden="false"] {
                display: block;
            }
        }
    }
}
