.information{
  &-mv {
    background: #626262 url(/images/loan/articles/mv.jpg) center center no-repeat;
    position: relative;

    @include view-at(tab) {
      background: #626262 url(/images/loan/articles/mv-sp.jpg) top center no-repeat;
      background-size: 100% auto;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgba(9, 109, 174, .8);
    }

    &-contents {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include view-at(tab) {
        display: block;
      }
    }

    &-title {
      font-size: rem(36px);
      color: #fff;
      font-weight: $bold;
      letter-spacing: .1em;
      margin-bottom: 16px;
      line-height: 1.1;

      @include view-at(tab) {
        font-size: rem(32px);
        margin-bottom: 12px;
        padding-top: 5px;
      }

      small {
        font-size: rem(16px);
        letter-spacing: 0;

        @include view-at(tab) {
          font-size: rem(14px);
        }
      }
    }

    &-description {
      color: #fff;
      font-size: rem(15px);
      letter-spacing: .06em;
      font-weight: $bold;
      line-height: rem(16px);
      padding-bottom: 10px;
      font-feature-settings: "palt";

      @include view-at(tab) {
        font-size: rem(15px);
        line-height: rem(15px);
        padding-bottom: 15px;
      }
    }

    &-nav {
      background-color: $color-blue-light;
      width: 510px;
      padding: 36px 30px;

      @include view-at(tab) {
        margin: 0 -30px;
        padding: 15px 20px;
        width: auto;
      }

      @include view-at(sp) {
        margin: 0 -20px;
      }

      &-list {
        list-style: none;
        margin: -10px;
        padding: 0;
        display: flex;

        @include view-at(tab) {
          margin: -5px;
        }

        li {
          padding: 10px;
          flex-grow: 1;

          @include view-at(tab) {
            padding: 5px;
          }
        }
      }
    }

    &-button {
      width: 100%;
      height: 60px;
      border-radius: 36px;
      font-size: rem(16px);
      padding: 22px 20px;
      font-weight: $bold;
      min-width: auto;

      @include view-at(sp) {
        height: 44px;
        padding: 14px 0;
        font-size: rem(14px);
        font-feature-settings: "palt";
      }
    }

  }

  &-column{
    margin-bottom: 40px;

    &-caution {
      font-size: 13px;
      line-height: 1.4;
    }

    &-date {
      text-align: right;
      margin: -25px 0 10px;
      font-size: rem(14px);

      @include view-at(sp) {
        font-size: rem(13px);
        margin-top: 0;
      }
    }

    &-item{
      display: block;
      width: 100%;
      background-color: $color-white;
      box-shadow: 2px 3px 8px rgba(0,0,0,0.31);
      color:$color-black;
    }

    &-header{
      display: flex;
      justify-content: space-between;

      @include view-at(sp){
        flex-wrap: wrap-reverse;

        &.list {
          flex-direction: row-reverse;
        }
      }

      &-text {
        display: flex;
        width: 70%;

        @include view-at(sp) {
          display: block;
        }
      }

      &-num{
        width:10%;
        background: linear-gradient(90deg, #008ddd 0%, #004d8f 100%);
        color:$color-white;
        font-size:20px;
        padding: 0 15px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include view-at(sp){
          width: 20%;
        }

        &.list {
          width: 15%;

          @include view-at(sp){
            background: $color-blue-light;
            color:$color-blue-dark;
            display: block;
            padding: 10px 10px 0;
            width: 100%;
            font-size: 15px;
            font-weight: $bold;
          }
        }
      }

      &-title{
        width:60%;
        padding: 30px 20px;
        background: $color-blue-light;
        color:$color-blue-dark;
        font-size: 20px;
        font-weight: $bold;
        line-height: 1.6;
        letter-spacing: .1rem;
        display: flex;
        align-items: center;
        position: relative;

        @include view-at(sp){
          width: 80%;
        }

        &.list {
          width: 85%;

          @include view-at(sp){
            width: 100%;
            padding: 0 10px 10px;
            font-size: 15px;
            letter-spacing: 0;
            font-feature-settings: "palt";
          }
        }
      }

      &-image{
        flex-shrink: 0;
        width:30%;

        @include view-at(sp){
          width:100%;

          &.list {
            width: 30%;

            img {
              height: 100%;
              object-fit: cover;
            }
          }
        }

        img{
          width: 100%;
          display: block;
        }

      }

      &--details{
        margin-bottom: 30px;

        @include view-at(sp){
          flex-wrap: nowrap;
        }

        .information-column-header-num{
          width: 20%;
          font-size: 26px;

          @include view-at(sp){
            width:80px;
            padding: 20px 0;
          }

        }

        .information-column-header-title{
          width:80%;
          padding: 24px 30px;
          color:$color-black;
          font-size:24px;

          @include view-at(sp){
            width:100%;
            padding: 15px 20px;
            font-size: 20px;
            line-height: 1.5;
          }

        }
      }

      &--relation{
        flex-wrap: wrap-reverse;

        flex-direction: row-reverse;
        .information-column-header-title{
          width:70%;
          padding: 30px;

          @include view-at(sp){
            width: 100%;
          }

        }

        .information-column-header-image{
          width:30%;

          @include view-at(sp){
            width: 100%;
          }
        }
      }

      &--case{
        flex-wrap: wrap-reverse;

        flex-direction: row-reverse;
        .information-column-header-title{
          width:70%;
          padding: 30px;
          align-items: flex-start;

          @include view-at(sp){
            width: 100%;
            padding-bottom: 70px;
          }

        }

        .information-column-header-image{
          width:30%;

          @include view-at(sp){
            width: 100%;
          }
        }
      }

    }

    &-contents{
      padding: 30px 35px;

      @include view-at(sp) {
        &.list {
          padding: 10px;
          font-size: 14px;
        }
      }

      &--case {
        position: relative;
        padding-bottom: 45px;
      }

      &-button {
        display: block;
        width: 140px;
        position: absolute;
        bottom: 15px;
        right: 15px;
        font-size: rem(14px);
        background-color: #fff;
        border: 1px solid $color-text;
        text-align: center;
        padding: 13px 30px 13px 20px;
        border-radius: 3px;

        &::after {
          content: '';
          width: 8px;
          height: 8px;
          border-top: 2px solid $color-blue;
          border-right: 2px solid $color-blue;
          transform: rotate(45deg);
          display: inline-block;
          position: absolute;
          top: calc(50% - 4px);
          right: 15px;
          transition: all 0.3s;
        }
      }
    }

    &-select-list{
      margin: -10px;
      display: flex;
      justify-content: flex-start;
      align-items: stretch;

      @include view-at(sp){
        display: block;
        margin: -10px;
      }

      &-item{
        padding: 10px;
        width:calc(100% / 3);

        @include view-at(sp){
          padding: 10px;
          width: 100%;
        }

        a{
          width:100%;
          min-width: auto;
          text-align: left;
          height: 100%;
          display: flex;
          align-items: center;

          &.u-align-center {
            text-align: center;
            justify-content: center;
          }
        }

        &--small{
          width:calc(100% / 3);

          @include view-at(sp){
            width: 100%;
          }
        }
      }
    }

  }



  &-keyword{

    &-list{
      display: flex;
      margin: -15px;
      justify-content: center;

      @include view-at(tab){
        flex-wrap: wrap;
      }

      &--sp{
        @include view-at(sp){
          display: block;

          .information-keyword-list-item{
            width:100%;
          }

        }

      }

      &-item{
        padding: 15px;
        width:calc(100% / 3);

        @include view-at(tab){
          width:calc(100% / 3);
        }

        @include view-at(sp){
          width: 50%;
        }

        a{
          display: block;
        }
      }

    }

    &-panel{
      background: $color-blue-light;

      &-contents{
        padding: 60px 74px 60px 93px;

        @include view-at(tab){
          padding: 30px;
        }

        @include view-at(sp){
          padding: 20px;
        }
      }

      &-head{

        &-line {
          display: block;
          font-size:36px;
          font-weight: $bold;
          color:$color-blue-dark;
          margin-bottom: 1rem;

          span{
            color:$color-black;
            font-weight: normal;
          }
        }

      }

    }

    &-sublist{
      &-item{
        padding-left: 15px;
        padding-bottom: 15px;
        position: relative;
        line-height: 1.5;
        font-size: rem(15px);

        @include view-at(sp){
          width:100%;
        }

        &::before {
          content: '';
          width: 8px;
          height: 8px;
          border-top: 1px solid $color-blue;
          border-right: 1px solid $color-blue;
          transform: rotate(45deg);
          display: inline-block;
          position: absolute;
          top: 6.5px;
          left: 0;
        }

        &:last-child{
          padding-bottom: 0;
        }

        a{
          display: inline-block;
          color:$color-black;
          font-weight: $bold;
        }
      }
    }
  }

  &-link {
    display: block;
    background: radial-gradient(ellipse farthest-side at top 15% left 35%, #6dc4f2 0%, #005cad 100%);
    color: $color-white;
    text-align: center;
    padding: 28px;

    @include view-at(tab) {
      padding: 22px;
    }

    &-inner {
      position: relative;
      display: inline-block;
      padding-left: 30px;
      font-size: rem(15px);
      font-weight: $bold;

      @include view-at(tab) {
        font-size: rem(14px);
      }

      &::before{
        content: '';
        position: absolute;
        top: -6px;
        left: 0;
        background: $color-white;
        border-radius: 50%;
        width: 25px;
        height: 25px;
      }
      &::after{
        content: '';
        width: 8px;
        height: 8px;
        border-top:2px solid $color-blue;
        border-right: 2px solid $color-blue;
        transform: rotate(45deg);
        display: inline-block;
        position: absolute;
        top: 3px;
        left: 7px;
      }
    }
  }

  &-cta {

  }
}