/* --------------------------
ご融資チャート
-------------------------- */
.p-chart{
  &-list{

    &-item{
      margin: 20px 0;
      border:1px solid $color-blue-dark;
      border-radius: 3px;

      &-finish{
        background: $color-blue-dark;

        .p-chart-panel{
          &-step{
            background:$color-white;

            &::before,&::after{
              content: none;
            }

            &-num{
              background: $color-blue-light;
            }
          }

          &-contents{
            color:$color-white;
          }

        }

      }

    }

  }

  &-panel{
    padding: 10px;
    display: flex;

    @include view-at(sp){
      display: block;
    }

    &-step{
      background: $color-blue-light;
      flex-basis: 180px;
      flex-shrink: 0;
      padding: 10px 0;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      @include view-at(sp){
        margin-bottom: 10px;
      }

      &::before{
        content:'';
        position: absolute;
        bottom:-25px;
        left:50%;
        width:4px;
        height: 25px;
        background: $color-blue;

        @include view-at(sp){
          content: none;
        }

      }

      &::after{
        content: '';
        position: absolute;
        bottom:-25px;
        left:calc(50% - 8.5px);
        width:20px;
        height: 20px;
        border-left: 4px solid $color-blue;
        border-bottom: 4px solid $color-blue;
        transform: rotate(-45deg);

        @include view-at(sp){
          content: none;
        }

      }

      &-num{
        background: $color-white;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        color:$color-blue-dark;
        font-size: 17px;
        line-height: 60px;
        font-weight: $bold;
        text-align: center;
      }

    }

    &-contents{
      padding-left: 20px;

      @include view-at(sp){
        padding-left: 0;
      }

    }

  }

}