@charset "UTF-8";

.top {
  &-container {
    max-width: 920px;
    margin: 0 auto;
    padding: 0 20px;
  }

  &-link {
    font-size: 15px;
    position: relative;
    padding-right: 15px;
    display: inline-block;

    &::after {
      content: "";
      width: 8px;
      height: 8px;
      border-top: 2px solid $color-blue;
      border-right: 2px solid $color-blue;
      transform: rotate(45deg);
      display: inline-block;
      position: absolute;
      top: calc(50% - 4px);
      right: 2px;
      transition: all 0.3s;
    }

    &:hover {
      opacity: 1;

      &::after {
        transform: rotate(45deg) translate(2px, -2px);
      }
    }
    &--border {
      border-bottom: 1px solid $color-blue;
      padding-bottom: 5px;
    }
  }
  &-keyvisual {
    img {
      display: block;
      width: 100%;
    }
  }

  &-goods {
    position: relative;

    &-container {
      width: 1270px;

      @include view-at(tab) {
        width: 100%;
      }
    }

    &-image {
      background-image: url(../../images/top/top-goods-image.jpg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100%;
      z-index: -1;
      width: calc(((100% - 1210px) / 2) + 410px);
      position: absolute;
      top: 0;
      left: 0;
      @include view-at(tab) {
        display: none;
      }
    }
    &-contents {
      display: flex;
      align-items: center;
      margin: 0 -30px;
      @include view-at(tab) {
        margin: 0;
        flex-direction: column;
      }
      &-item {
        padding: 65px 30px;
        @include view-at(tab) {
          padding: 30px 0;
        }
      }
    }
    &-section {
      padding-right: 60px;
      color: $color-white;
      z-index: 2;
      @include view-at(tab) {
        padding-right: 0;
        padding: 30px;
        background-image: url(../../images/top/top-goods-image.jpg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: auto;
        width: 100%;
      }
      &-title {
        font-size: 31px;
        font-weight: $bold;
        margin-bottom: 40px;
      }
      .c-button {
        background-color: transparent;
        border-color: $color-white;
        color: $color-white;
        &:hover {
          color: $color-blue-dark;
          background-color: $color-white;
          text-decoration: none;
          opacity: 1;
        }
      }
    }
    &-panel {
      width: 820px;
      flex-shrink: 0;
      @include view-at(tab) {
        width: 100%;
        margin: -30px 0 0;
      }
      &-list {
        display: flex;
        flex-wrap: wrap;
        margin: -10px;
        @include view-at(sp) {
          flex-direction: column;
        }
        &-item {
          padding: 10px;
          width: 33.333%;
          @include view-at(sp) {
            width: 100%;
          }
          a {
            display: block;
            // background: url(../../images/top/top-panel.png);
            background: #E5F5FF;
            // background: radial-gradient(circle at top 11.5px left 47.5px, #eff9ff 237.5px, #E5F5FF 237.5px);;
            // background-position: center;
            // background-repeat: no-repeat;
            // background-size: cover;
            border: 1px solid $color-gray;
            padding: 20px 5px;
            transition: all 0.3s;
            position: relative;
            overflow: hidden;

            @include view-at(tab) {
              padding: 20px;
            }

            &::before {
              display: block;
              content: '';
              width: 360px;
              height: 360px;
              background-color: #eff9ff;
              border-radius: 50%;
              position: absolute;
              top: -188px;
              left: -145px;
            }

            &:hover {
              // background: url(../../images/top/top-panel_hover.png);
              background: #fff;
              // background: radial-gradient(circle at top 11.5px left 47.5px, #eef8ff 237.5px, #fff 237.5px);;
              // background-position: center;
              // background-repeat: no-repeat;
              // background-size: cover;
              opacity: 1;
              .top-goods-panel-area {
                justify-content: flex-start;
                &-description {
                  height: auto;
                  overflow: visible;
                  opacity: 1;
                }
              }

              .top-link--border {
                border-bottom: 0;
                padding-bottom: 6px;
              }
            }
          }
        }
      }
      &-area {
        position: relative;
        height: 176px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        @include view-at(sp) {
          height: auto;
        }

        &-title {
          font-size: 18px;
          line-height: 27px;
          letter-spacing: 0.12em;
          font-weight: $bold;
          color: $color-blue-dark;
          text-align: center;
          margin-bottom: 0.3em;
        }
        &-description {
          display: block;
          font-size: 12px;
          line-height: 1.25;
          letter-spacing: 0.1em;
          color: $color-black;
          opacity: 0;
          transition: opacity 0.3s;
          height: 0;
          overflow: hidden;
          width: 100%;

          @include view-at(sp) {
            display: block;
            height: auto;
            overflow: auto;
            margin-bottom: 2em;
            opacity: 1;
            font-size: 15px;
            line-height: 1.3;
          }
        }
        &-link {
          position: absolute;
          bottom: 0;
          right: 15px;
        }
      }
    }
  }

  &-service {
    &-panel {
      display: block;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 252px;
      width: 100%;
      padding: 10px;
      &-contents {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 232px;
        border: 1px solid rgba(0, 160, 233, 0.5);
        border-radius: 3px;
        color: $color-white;
        font-size: 18px;
        letter-spacing: 0.12em;
        line-height: 1.5;
      }
      &--feature {
        background-image: url(../../images/top/top-service-panel--feature.jpg);
      }
      &--episode {
        background-image: url(/images/top/top-service-panel--episode.jpg);
      }
      &--business {
        background-image: url(../../images/top/top-service-panel--business.jpg);
      }
      &--about {
        background-image: url(../../images/top/top-service-panel--about.jpg);
      }
      &--access {
        background-image: url(../../images/top/top-service-panel--access.jpg);
      }
    }
  }

  &-faq {
    &-list {
      margin: -10px 0;
      &-item {
        padding: 10px 0;
      }
    }
    &-question {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 25px;
      background: $color-white;
      color: $color-black;
      font-weight: $bold;
      position: relative;

      @include view-at(sp) {
        padding: 15px 60px 15px 20px;
      }

      &:hover {
        opacity: 1;
      }

      &::before{
        content:'';
        display: block;
        position: absolute;
        top:calc(50% - 20px);
        right:25px;
        width:40px;
        height: 40px;
        background: $color-blue-light;
        border-radius: 50%;
        margin-left: 10px;

        @include view-at(sp) {
          top:calc(50% - 15px);
          width: 30px;
          height: 30px;
          right: 15px;
        }
      }

      &::after {
        content: '\e901';
        font-family: 'fa';
        font-weight: 300;
        display: block;
        position: absolute;
        top:calc(50% - 20px);
        right: 25px;
        width: 40px;
        height: 40px;
        font-size: rem(30px);
        color:$color-blue-dark;
        text-align: center;
        line-height: 40px;
        letter-spacing: 0;

        @include view-at(sp) {
          top:calc(50% - 15px);
          width: 30px;
          height: 30px;
          right: 15px;
          font-size: rem(20px);
          line-height: 30px;
        }
      }

      &.active{
        &::after{
          content: '\e900';
        }
      }

      &-text {
        display: flex;
        align-items: center;
      }
      &-icon {
        font-size: 24px;
        padding-right: 20px;
        color: $color-blue;
      }
    }
    &-answer {
      display: none;
      &.active {
        display: block;
      }
      &-contents {
        padding: 25px;
        background: $color-blue;
        color: $color-white;
        display: flex;
        align-items: center;
        @include view-at(sp) {
          padding: 20px;
        }
      }
      &-icon {
        font-size: 24px;
        padding-right: 20px;
      }
    }
    &-buuton {
      display: inline-block;
      width: auto;
      padding: 17px 20px;
      cursor: pointer;
      text-decoration: none;
      text-align: center;
      color: $color-blue;
      position: relative;
      line-height: 1;
      transition: color 0.3s, background-color 0.3s;
      font-size: rem(15px);
      min-width: 250px;
    }
  }
}
