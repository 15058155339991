.p-case{
  &-list{
    margin: -30px 0;

    &-item{
      padding: 30px 0;
    }
  }

  &-intro{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background: radial-gradient(ellipse farthest-side at top 15% left 35%, #6dc4f2 0%, #005cad 100%);

    @include view-at(sp) {
      display: block;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: 27px;
      right: 27px;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: $color-white;

      @include view-at(sp) {
        bottom: 15px;
        right: 15px;
      }
    }

    &::after {
      content: '\e901';
      font-family: 'fa';
      font-weight: 300;
      display: block;
      position: absolute;
      bottom: 26px;
      right: 27px;
      width: 32px;
      height: 32px;
      font-size: rem(26px);
      text-align: center;
      line-height: 32px;
      letter-spacing: 0;
      padding-left: 1px;

      @include view-at(sp) {
        bottom: 15px;
        right: 15px;
      }
    }

    &.active {

      &::after {
        content: '\e900';
        font-family: 'fa';
        font-weight: 300;
        bottom: 26px;
        font-size: rem(26px);

        @include view-at(sp) {
          bottom: 17.5px;
        }
      }
    }

    &-image{
      width: 30%;

      @include view-at(sp) {
        width: 100%;
      }

      img{
        width:100%;
        display: block;
      }
    }

    &-detail{
      width:70%;
      padding: 40px;
      color:$color-white;
      align-self: stretch;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include view-at(sp) {
        width: 100%;
      }
    }
  }

  &-content {
    display: none;

    &.visible {
      display: block;
    }
  }
}
