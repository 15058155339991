/* --------------------------
人気の記事
-------------------------- */
.p-hot{
  &-title{
    font-size:22px;
    color:$color-blue-dark;
    font-weight: $bold;
    text-align: center;
    border-top:1px solid $color-blue-dark;
    border-bottom:1px solid $color-blue-dark;
    padding: 12px 0;
  }

  &-list{
    margin: 5px 0 20px;

    @include view-at(tab){
      display: flex;
      flex-wrap: wrap;
    }

    &-item{
      a {
        display: flex;
        padding: 20px 0;
        border-bottom:1px solid $color-black;
        color: $color-text;

        @include view-at(tab){
          width:50%;
          padding: 20px;
        }

        @include view-at(sp){
          width:100%;
          padding: 20px 0;
        }
      }
    }
  }

  &-thumb{
    padding-right: 20px;
    width:40%;
    flex-shrink: 0;

    img{
      width:100%;
      display: block;
    }
  }

  &-cta-contact{
    border:1px solid $color-black;
    background: $color-blue-light;

    &-title{
      font-size: rem(12px);
      font-weight: $bold;
      letter-spacing: 0;
      font-feature-settings: "palt";
    }

    &-number {
      font-size: rem(30px);
      font-weight: $bold;
      margin-top: 1px;
      letter-spacing: 0.03em;
      margin-left: 1px;

      @include view-at(tab) {
        font-size: rem(24px);
      }
    }

    &-contents{
      padding: 15px;
    }

    &-mail {
      background-color: $color-navy;
      display: flex;
      width: 100%;
      height: 68px;
      color: $color-white;
      font-size: rem(20px);
      text-align: center;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      transition: all 0.3s;
      border: 1px solid $color-navy;

      @include view-at(tab) {
        margin-left: 0;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      &:hover {
        background-color: $color-white;
        color: $color-navy;
        opacity: 1;

        &::before {
          background-image: url(/images/common/icon-mail-inverse.png);
        }

      }

      &::before {
        content: '';
        display: inline-block;
        width: 29px;
        height: 20px;
        margin-right: 10px;
        background: transparent url(/images/common/icon-mail.png) center center no-repeat;
        background-size: contain;
      }
    }

    &-reserve {
      background-color: $color-red-dark;
      display: flex;
      width: 100%;
      height: 68px;
      color: $color-white;
      font-size: rem(20px);
      text-align: center;
      align-items: center;
      justify-content: center;
      transition: all 0.3s;
      border: 1px solid $color-red-dark;

      @include view-at(tab) {
        margin-left: 0;
      }

      &:hover {
        background-color: $color-white;
        color: $color-red-dark;
        opacity: 1;

        &::before {
          background-image: url(/images/common/icon-reserve-inverse.png);
        }

      }

      &::before {
        content: '';
        display: inline-block;
        width: 31px;
        height: 31px;
        margin-right: 10px;
        background: transparent url(/images/common/icon-reserve.png) center center no-repeat;
        background-size: contain;
      }

    }

  }

}