/* --------------------------
見出し
-------------------------- */

.c-h1 {
  margin: 63px auto 70px;
  color: $color-text;
  text-align: center;
  font-weight: $bold;
  font-size: rem(31px);
  line-height: 1.5em;
  letter-spacing: 0.1em;
  padding: 33px 30px;
  position: relative;

  @include view-at(tab) {
    font-size: rem(18px);
    margin: 60px auto;
    padding: 33px 0;
  }

  &--large {
    line-height: 1.2;

    @include view-at(sp) {
      font-size: rem(26px);
    }

    small {
      font-size: rem(15px);
      letter-spacing: 0;

      @include view-at(sp) {
        font-size: rem(14px);
      }
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: block;
    width: 35px;
    height: 2px;
    transform: rotate(135deg);
    margin: 0 auto;
    background-color: rgba(0, 160, 233, 0.7);

    @include view-at(tab) {
      width: 26px;
    }
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    width: 35px;
    height: 2px;
    transform: rotate(135deg);
    margin: 0 auto;
    background-color: rgba(0, 160, 233, 0.7);

    @include view-at(tab) {
      width: 26px;
    }
  }
}

.c-h2 {
  color: $color-white;
  font-size: rem(22px);
  text-align: center;
  font-weight: $bold;
  letter-spacing: 0.2em;
  line-height: 1.5em;
  margin-bottom: 50px;
  background-color: $color-gray-snow;
  padding: 18px 30px;
  background: linear-gradient(90deg, #008ddd 0%, #004d8f 100%);

  @include view-at(tab) {
    padding: 13px 15px;
  }
}

.c-h3 {
  color: $color-text;
  font-size: rem(17px);
  font-weight: $bold;
  line-height: 1.5em;
  letter-spacing: 0.1em;
  margin-bottom: 27px;
  background-color: $color-blue-light;
  border-left: 12px solid $color-blue;
  padding: 16px 15px;

  @include view-at(sp) {
    font-size: rem(16px);
    padding: 10px;
  }
}

.c-h4 {
  color: $color-text;
  font-size: rem(18px);
  font-weight: $bold;
  line-height: rem(36px);
  border-bottom: 3px solid $color-blue;
  margin-bottom: 22px;
  display: inline-block;
  letter-spacing: 0.1em;
}

.c-h5 {
  font-size: rem(20px);
  font-weight: $bold;
  color: $color-blue;
  line-height: rem(28px);
  letter-spacing: 0.1em;
  margin-bottom: 20px;
}

.c-h6 {
  font-size: nth($font-size, 6);
  line-height: nth($line-height, 6);
  letter-spacing: 0.08em;
  font-weight: $medium;
  margin-bottom: 5px;

  @include view-at(sp) {
    font-size: nth($font-size-sp, 6);
    line-height: nth($line-height-sp, 6);
  }
}

.c-h--center-line {
  @include center-line();
  margin-bottom: 5px;
}