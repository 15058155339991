.faq{
  &-list{
    display: flex;
    justify-content: space-between;
    margin: -15px;

    @include view-at(tab){
      flex-wrap: wrap;
    }

    &-item{
      padding: 15px;
      width: 100%;

      @include view-at(tab){
        width:50%;
      }

      @include view-at(sp){
        width: 100%;
      }

      &-number{
        color:$color-blue;
        font-size: 25px;
        margin-bottom: 10px;
      }

      >a{
        width: 100%;
      }

    }

  }

  &-h2{
    font-size:25px;
    letter-spacing: 0.2rem;

    // &-number{
    //   font-size: 50px;
    //   margin-bottom: .5rem;
    //   color: transparent;
    //   // color:$color-blue-dark;
    //   background: linear-gradient(90deg, #008ddd 0%, #004d8f 100%);
    //   background-clip: text;
    //   display: inline-block;
    // }

  }

  &-panel{
    background: $color-blue-light;
  }

  &-header{
    margin-bottom: 30px;

    &--blue{
      >a{
        background: $color-blue-light!important;

        &::before{
          background: $color-white!important;
        }

        &::after{
          color:$color-blue!important;
        }
      }
    }


    &-question{
      display: flex;
      align-items: center;

      &-icon{
        color:$color-blue;
        padding-right: 20px;
        font-size: 24px;
      }
    }

    >a{
      display: block;
      padding: 25px 80px 25px 25px;
      background: $color-white;
      position: relative;
      color:$color-black;
      font-weight: $bold;

      @include view-at(tab){
        padding:15px 80px 15px 20px;
      }

      &::before{
        content:'';
        display: block;
        position: absolute;
        top:calc(50% - 20px);
        right:25px;
        width:40px;
        height: 40px;
        background: $color-blue-light;
        border-radius: 50%;
        margin-left: 10px;
      }

      &::after {
        content: '\e901';
        font-family: 'fa';
        font-weight: 300;
        display: block;
        position: absolute;
        top:calc(50% - 20px);
        right: 25px;
        width: 40px;
        height: 40px;
        font-size: rem(30px);
        color:$color-blue-dark;
        text-align: center;
        line-height: 40px;
        letter-spacing: 0;

      }

      &.active{
        &::after{
          content: '\e900';
          font-family: 'fa';
          font-weight: 300;
          top:calc(50% - 20px);
          font-size: rem(30px);
        }
      }

    }

    &-answer{
      display: none;
      padding: 20px 0;

      &.visible {
        display: block;
      }

      a {
        word-break: break-all;
      }

    }

  }
}