.realestate-finance{

  &-accent{
    color:$color-blue-dark;
  }

  &-list{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: -15px;

    &-item{
      width: calc(100% / 3);
      padding: 15px;

      @include view-at(tab){
        width:50%;
      }

      @include view-at(sp){
        width:100%;
      }

    }
  }

  &-contents{
    padding: 35px 25px;
    background-image: url(/images/loan/realestate-finance/panel-link.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border:1px solid $color-gray;
    display: flex;
    width:100%;
    height: 100%;
  }

  &-h{
    font-size: 20px;
    color:$color-blue-dark;
    font-weight: $bold;
    margin-bottom: 20px;
    line-height: 1.5;
  }

  &-case{
    padding: 35px 30px;
    display: flex;
    width:100%;
    height: 100%;

    &-label{
      display: inline-block;
      color:$color-white;
      padding: 2px 14px;
      background: linear-gradient(90deg, #008ddd 0%, #004d8f 100%);
      margin-bottom: 15px;
    }
  }

}