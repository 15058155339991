.complaint {
  &-h{
    font-size: rem(16px);
    line-height: rem(22.5px);
    font-weight:$bold;
    color:$color-blue-dark;
  
    @include view-at(tab) {
      font-size: rem(16px);
      line-height: rem(22.5px);
    }  
  }
  &-panel{
    background:$color-gray-snow;
    &-h {
      font-size: rem(18px);
      line-height: rem(32px);
      font-weight: $bold;
      padding-bottom: 6px;
      border-bottom: 1px solid $color-black;
      margin-bottom: 20px;
      color:$color-blue;
      @include view-at(tab) {
        font-size: rem(15px);
        line-height: rem(27px);
      }
    }
    &-text{
      font-size: rem(16px);
      line-height: rem(24px);
      font-weight: $bold;
      margin-bottom: 0;
      @include view-at(tab) {
        font-size: rem(13px);
      } 
    }
    &-contents{
      padding: 40px 30px;
      &-item{
        display: flex;
      }
    }
  }
}