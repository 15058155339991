@charset "UTF-8";

.sitemap {
  .u-border-bottom{
    border-bottom: 1px solid #808080;
    @include view-at(tab){
      padding-bottom: 0;
    }
  }
  &-section {
    margin: 20px 0;

    @include view-at(tab) {
      margin: 0;
    }
  }

  &-h3 {
    color: $color-blue;
    font-size: rem(23px);
    font-weight: $bold;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }

  &-h4 {
    font-size: rem(19px);
    line-height: rem(33px);
    font-weight: $bold;
    position: relative;
    padding-left: 20px;
    margin-bottom: 12px;

    @include view-at(tab) {
      font-size: rem(18px);
      line-height: rem(22px);
      padding: 15px;
      margin: 0;
      border-bottom: 1px solid $color-border;
    }

    &::before {
      content: '';
      width: 10px;
      height: 10px;
      border-top: 1px solid $color-blue;
      border-right: 1px solid $color-blue;
      transform: rotate(45deg);
      display: inline-block;
      position: absolute;
      top: 10px;
      left: 0;

      @include view-at(tab) {
        display: none;
      }
    }
  }

  &-icon {
    width: 37px;
    margin-right: 5px;
  }


  &-list {
    margin-bottom: 25px;


    @include view-at(tab) {
      margin: 0;
    }
    &-item{
      display: flex;
      justify-content: space-between;
      @include view-at(tab){
        display: block;
      }
      >ul{
        width: calc(100% / 3);
        padding-right: 15px;
        &:last-child{
          padding-right: 0;
        }
        @include view-at(tab) {
          width: 100%;
          padding-right: 0;
        }
      }
    }

    > li {
      line-height: rem(26px);
      font-weight: $bold;
      position: relative;
      padding-left: 15px;
      margin-bottom: 20px;

      @include view-at(tab) {
        padding: 0;
        margin: 0;
        font-weight: $normal;
      }

      &::before {
        content: '';
        width: 7px;
        height: 7px;
        border-top: 1px solid $color-blue;
        border-right: 1px solid $color-blue;
        transform: rotate(45deg);
        display: inline-block;
        position: absolute;
        top: 6.5px;
        left: 0;
        transition: all 0.3s;

        @include view-at(tab) {
          display: none;
        }
      }

      &:hover {
        &::before {
          border-color: $color-blue-dark;
          transform: rotate(45deg) translate(2px, -2px);
        }
      }

      > a {
        color: $color-text;

        @include view-at(tab) {
          display: block;
          padding: 15px;
          position: relative;
          border-bottom: 1px solid $color-border;

          &::after {
            content: '';
            width: 8px;
            height: 8px;
            border-top: 1px solid $color-gray-dark;
            border-right: 1px solid $color-gray-dark;
            transform: rotate(45deg);
            display: inline-block;
            position: absolute;
            top: calc(50% - 4px);
            right: 20px;
          }
        }
      }
    }

    &-internal {
      margin-left: -15px;
      margin-top: 11.5px;
      &--horizontal{
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        >li{
          width: calc(100% / 2);
        }
      }

      @include view-at(tab) {
        margin: 0;
      }

      >li {
        line-height: rem(26px);
        position: relative;
        padding-left: 15px;
        margin-bottom: 10px;

        @include view-at(tab) {
          padding: 0;
          margin: 0;
          border-bottom: 1px solid $color-border;
          font-size: rem(14px);
        }

        &::before {
          content: '';
          width: 7px;
          height: 7px;
          border-top: 1px solid $color-blue;
          border-right: 1px solid $color-blue;
          transform: rotate(45deg);
          display: inline-block;
          position: absolute;
          top: 6.5px;
          left: 0;
          transition: all 0.3s;

          @include view-at(tab) {
            display: none;
          }
        }

        &:hover {
          &::before {
            border-color: $color-blue-dark;
            transform: rotate(45deg) translate(2px, -2px);
          }
        }

        a {
          color: #484B44;
          font-weight: $normal;

          @include view-at(tab) {
            display: block;
            padding: 15px;

            &::after {
              content: '';
              width: 8px;
              height: 8px;
              border-top: 1px solid $color-gray-dark;
              border-right: 1px solid $color-gray-dark;
              transform: rotate(45deg);
              display: inline-block;
              position: absolute;
              top: calc(50% - 4px);
              right: 20px;
            }
          }
        }
      }
    }
  }

}