.real-estate{
  &-accent-color{
    color:$color-blue-dark;
  }

  &-border{
    border:30px solid;

    @include view-at(sp){
      border:none;
    }

    &--blue{
      border-color:$color-blue-light
    }

    &--white{
      border-color:$color-white;
    }
  }

  &-mv{
    background-image:url(/images/real-estate/mv.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    max-height: 860px;
    height: 66vh;

    @include view-at(sp){
      max-height: 550px;
    }

    &-title{
      font-size:45px;
      line-height: 1.4;
      font-weight: $bold;
      margin-bottom: 25px;
      
      @include view-at(sp) {
        font-size: rem(38px);
      }
    }

    &-description{
      font-size: rem(22px);

      @include view-at(sp) {
        font-size: rem(18px);
      }
    }

    &-contents{
      display: flex;
      max-height: 860px;
      height: 66vh;
      justify-content: flex-start;
      align-items: center;

      @include view-at(sp){
        max-height: 550px;
      }
    }

  }

  &-list{
    display: flex;
    margin: -15px;

    @include view-at(tab){
      flex-wrap: wrap;
      justify-content: center;
    }

    &-item{
      padding: 15px;
      width:100%;

      @include view-at(tab){
        width:50%;
      }

      @include view-at(sp){
        width:100%;
      }

      a{
        display: block;
        position: relative;
        width:100%;
        height: 100%;
        background: url(/images/real-estate/panel-link.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        border:1px solid $color-gray;
        padding: 45px 20px 20px;
      }

    }

    &-label{
      font-size:14px;
      color:$color-white;
      padding: 8px 10px;
      background: $color-blue;
      border-radius: 5px;
      display: inline-block;
      margin-top: 15px;
    }

    &-link{
      margin-top: 30px;
      font-size:15px;
      position: relative;
      padding-right: 15px;
      display: inline-block;
      border-bottom:1px solid $color-blue;
      padding-bottom:5px;

      &::after {
        content: '';
        width: 8px;
        height: 8px;
        border-top: 2px solid $color-blue;
        border-right: 2px solid $color-blue;
        transform: rotate(45deg);
        display: inline-block;
        position: absolute;
        top: calc(50% - 4px);
        right: 2px;
        transition: all 0.3s;
      }

      &:hover {
          opacity: 1;

          &::after {
            transform: rotate(45deg) translate(2px, -2px);
          }

        }

      }
  }

  &-feature{

    &-title{
      font-size: 28px;
      font-weight: $bold;
      line-height: 2.2;
      margin: 30px 0;
    }

    &-list{
      display: flex;
      align-items: center;
      justify-content: center;
      margin: -30px;

      @include view-at(tab){
        flex-wrap: wrap;
      }

      @include view-at(sp){
        margin: -15px;
      }

      &-item{
        padding: 30px;
        width: 100%;

        @include view-at(tab){
          width:50%;
        }

        @include view-at(sp){
          width:100%;
          padding: 15px;
        }
      }

    }

    &-contents{
      background: $color-white;
      background-size: cover;
      height: 100%;
      border-radius: 50%;
      height: 330px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      @include view-at(tab){
        height: 295px;
      }

      @include view-at(sp){
        height: 335px;
      }
    }

  }

}