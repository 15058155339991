/* --------------------------
テーブル
-------------------------- */

.c-table {
  $border-color: #707070;

  width: 100%;

  thead {
    tr {
      th {
        padding: 15px;
      }
    }
  }

  tbody {
    th {
      padding: 15px;
      word-break: keep-all;
      font-weight: $normal;
      vertical-align: top;
      line-height: 1.5;
    }

    td {
      padding: 15px;
      line-height: 1.5;
      vertical-align: top;
    }
  }

  &--border {
    border-collapse: collapse;

    thead {
      tr {
        border-bottom: 1px solid $border-color;

        th {
          font-weight: $bold;
          padding: 20px 30px 20px 0;
        }
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid $border-color;
      }

      th {
        font-weight: $bold;
        padding: 20px 0;

        @include view-at(sp) {
          display: block;
          padding: 15px 0 0;
        }
      }
      td {
        padding: 20px 0 20px 30px;

        @include view-at(sp) {
          display: block;
          padding: 10px 0 15px;
        }
      }
    }
  }

  &--fullborder {
    td,th {
      border: 1px solid $border-color;
      text-align: center;
    }

    thead {
      background-color: #f0f0f0;
    }

    tbody {
      th {
        font-weight: $bold;
      }
    }
  }

  &--color {
    background-color: #f0f0f0;
    border-collapse: collapse;

    thead {
      th {
        background-color: #00A0E9;
        color: #fff;
        text-align: center;
        border: 2px solid #fff;
      }
    }

    tbody {
      th {
        background-color: #E5F5FF;
        text-align: left;
        font-weight: $bold;
        border: 2px solid #fff;
        vertical-align: middle;
      }

      td {
        text-align: left;
        vertical-align: middle;
        border: 2px solid #fff;
      }
    }

  }
}
