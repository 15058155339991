.simulation_payment {
  &-tabs {
    display: flex;
    align-items: center;
    margin: -135px -15px 55px;

    @include view-at(sp) {
      margin-top: 0;
      display: block;
    }


    &-item {
      padding: 15px;
      width: 100%;

      &-button {
        display: block;
        height: 80px;
        border: 2px solid #ababab;
        background-color: #f2f2f2;
        padding: 28px 10px 28px 103px;
        position: relative;
        font-size: rem(22px);
        font-weight: $bold;
        color: $color-text;
        width: 100%;

        @include view-at(sp) {
          font-size: rem(18px);
          padding-left: 60px;
        }

        &:hover {
          opacity: 1;
        }

        &::before {
          content: '';
          display: block;
          position: absolute;
          left: 55px;
          top: 20px;
          width: 38px;
          height: 38px;
          border-radius: 50%;
          border: 1px solid #707070;
          background-color: $color-white;

          @include view-at(sp) {
            left: 15px;
          }
        }

        &.active {
          border: 0;
          background: linear-gradient(90deg, #008ddd 0%, #004d8f 100%);
          color: $color-white;
          padding: 30px 10px 30px 105px;

          @include view-at(sp) {
            padding-left: 60px;
          }

          &::before {
            border: 0;
          }

          &::after {
            content: '';
            display: block;
            position: absolute;
            left: 65px;
            top: 30px;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            background-color: $color-blue-dark;

            @include view-at(sp) {
              left: 25px;
            }
          }
        }
      }
    }
  }

  &-simulator {
    display: none;
    margin: 0 -15px;

    &.visible {
      display: flex;
      justify-content: space-between;

      @include view-at(sp) {
        display: block;
        margin: 0;
      }
    }

    &-title {
      font-size: rem(25px);
      font-weight: $bold;
      color: $color-blue-dark;

      @include view-at(sp) {
        font-size: rem(18px);
      }
    }

    &-conditions {
      width: 600px;
      padding: 0 15px;

      @include view-at(sp) {
        width: 100%;
        padding: 0;
      }

      &-panel {
        background-color: $color-white;
        padding: 0 30px;
        position: relative;

        @include view-at(sp) {
          width: 100%;
          padding: 0 20px;
        }

        &::after {
          content: '';
          width: 40px;
          height: 38px;
          background: transparent url(/images/simulation_payment/arrow-right.png) center center no-repeat;
          background-size: contain;
          position: absolute;
          top: calc(50% - 19px);
          right: -53.5px;

          @include view-at(sp) {
            display: none;
          }
        }
      }

      &-item {
        border-bottom: 1px solid #484B44;
        padding: 24px 0 21px;

        &:last-child {
          border: 0;
        }
      }
    }

    &-form {
      &-text {
        height: 43px;
        width: 126px;
        vertical-align: bottom;
        margin-right: 5px;
        font-size: rem(28px);
        text-align: right;

        @include view-at(sp) {
          font-size: rem(18px);
        }

        &--large {
          width: 369px;
          height: 65px;
          font-size: rem(42px);
          background-color: $color-white;
          color: $color-text;

          @include view-at(sp) {
            font-size: rem(22px);
            width: 80%;
            height: 48px;
          }
        }

        &--middle {
          width: 147px;
          height: 65px;
          font-size: rem(42px);
          background-color: $color-white;
          color: $color-text;

          @include view-at(sp) {
            font-size: rem(22px);
            width: 80px;
            height: 48px;
          }
        }
      }
    }

    &-unit {
      font-size: rem(27px);
      font-weight: $bold;

      @include view-at(sp) {
        font-size: rem(18px);
      }
    }

    &-result {
      width: 505px;
      padding: 48px 15px 0;

      @include view-at(sp) {
        width: 100%;
        padding: 40px 0;
      }

      &-panel {
        padding: 30px 28px 30px 40px;
        background-color: $color-white;
        border: 2px solid $color-blue-dark;

        @include view-at(sp) {
          padding: 30px 20px;
        }
      }
    }

    &-button {
      min-width: auto;
      width: 100%;
      font-size: rem(20px);
      padding: 25px;
      border-radius: 36px;

      &--primary {
          background: linear-gradient(90deg, #008ddd 0%, #004d8f 100%);
          color: $color-white;
          border: 0;
          padding: 26px 20px;
      }
    }
  }
}