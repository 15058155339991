.download {
  &-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -7px;

    &-item {
      width: 50%;
      padding: 0 7px;

      @include view-at(pc) {
        @for $i from 1 through 10 {
          &.order-#{$i} {order: $i;}
        }
      }

      @include view-at(tab) {
        width: 100%;
      }
    }

    &-link {
      display: flex;
      align-items: flex-start;
      padding: 15px 10px;
      border-bottom: 1px solid $color-border;
      height: 100%;

      &:hover {
        background-color: $color-gray-snow;
        opacity: 1;
      }
    }

    &-number {
      color: $color-gray-dark;
      margin-right: 13px;
      flex-shrink: 0;
      padding-top: 2px;
    }

    &-pdf {
      margin-right: 11px;
      flex-shrink: 0;
      width: 16px;
      display: block;
    }

    &-title {
      padding-right: 11px;
      color: $color-gray-dark;
      text-decoration: underline;
      flex-grow: 1;
      font-size: rem(14px);
      line-height: rem(22px);
    }

    &-download {
      flex-shrink: 0;
      padding-top: 2px;
    }
  }

  &-install {
    padding: 25px 30px;
    background-color: #e5e5e5;
    display: flex;
    align-items: center;
    margin-bottom: 50px;

    &-banner {
      flex-shrink: 0;
      padding-right: 30px;
      width: 162px;
    }
  }
}