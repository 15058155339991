/* --------------------------
ヘッダー
-------------------------- */

.p-header {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: $color-white;
  width: 100%;

  @include view-at(pc) {
    min-width: $contents-width;
  }

  @include view-at(tab) {
    min-width: auto;
    box-shadow: 0 3px 6px rgba(0,0,0,0.1);
  }

  &-right {
    @include view-at(tab) {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  &-inner {
    background-color: rgba(255,255,255,0.75);
    max-width: 1366px;
    margin: 0 auto;
    padding: 0 26px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include view-at(mdpi) {
      padding: 0 10px;
    }

    @include view-at(tab) {
      display: block;
      padding: 0;
    }
  }

  &-logo {
    text-align: left;
    font-size: 0;

    @include view-at(tab) {
      padding: 10px 15px;
    }

    a {
      display: inline-block;
      line-height: 1;
      font-size: 0;

      @include view-at(pc) {
        img {
          width: auto;
          height: 54px;
        }
      }

      @include view-at(tab) {
        img {
          width: auto;
          height: 31px;
        }
      }
    }
  }

  &-contact {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    &-space {
      margin-left: 25px;
      margin-right: 2px;

      @include view-at(tab) {
        margin: 0;
      }
    }

    &-button {

      @include view-at(tab) {
        width: 100%;
      }

      &--mail {
        display: flex;
        width: 154px;
        height: 65px;
        font-size: rem(15px);
        text-align: center;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        transition: all 0.3s;
        border: 1px solid $color-navy;
        background-color: $color-white;
        color: $color-navy;

        @include view-at(sp) {
          margin-left: 0;
          margin-top: 10px;
          margin-bottom: 10px;
        }

        &:hover {
          opacity: 1;
          background-color: $color-navy;
          color: $color-white;

          &::before {
            background-image: url(/images/common/icon-mail.png);
          }
        }

        &::before {
          content: '';
          display: block;
          width: 20px;
          height: 15px;
          margin-bottom: 10px;
          background: transparent url(/images/common/icon-mail-inverse.png) center center no-repeat;
          background-size: contain;
        }
      }

      &--reserve {
        background-color: $color-red-dark;
        display: flex;
        flex-direction: column;
        width: 154px;
        height: 65px;
        color: $color-white;
        font-size: rem(15px);
        text-align: center;
        align-items: center;
        justify-content: center;
        transition: all 0.3s;
        border: 1px solid $color-red-dark;

        @include view-at(tab) {
          margin-left: 0;
          width: 100%;
          flex-direction: row;
          height: 47px;
        }

        &:hover {
          background-color: $color-white;
          color: $color-red-dark;
          opacity: 1;

          &::before {
            background-image: url(/images/common/icon-reserve-inverse.png);
          }
        }

        &::before {
          content: '';
          display: block;
          width: 23px;
          height: 21px;
          margin-bottom: 5px;
          background: transparent url(/images/common/icon-reserve.png) center center no-repeat;
          background-size: contain;

          @include view-at(tab) {
            margin-bottom: 0;
            margin-right: 10px;
          }
        }
      }

      &--tel {
        background-color: $color-navy;
        display: flex;
        flex-direction: column;
        width: 154px;
        height: 65px;
        color: $color-white;
        font-size: rem(15px);
        text-align: center;
        align-items: center;
        justify-content: center;
        transition: all 0.3s;
        border: 1px solid $color-navy;

        @include view-at(tab) {
          margin-left: 0;
          width: 100%;
          flex-direction: row;
          height: 47px;
        }

        &:hover {
          background-color: $color-white;
          color: $color-navy;
          opacity: 1;

          &::before {
            background-image: url(/images/common/icon-tel-inverse.png);
          }
        }

        &::before {
          content: '';
          display: block;
          width: 23px;
          height: 21px;
          margin-bottom: 5px;
          background: transparent url(/images/common/icon-tel.png) center center no-repeat;
          background-size: contain;

          @include view-at(tab) {
            margin-bottom: 0;
            margin-right: 10px;
          }
        }
      }
    }
  }
  &-tel {
    font-size: rem(37px);
    font-weight: $bold;
    letter-spacing: 0.08em;

    @include view-at(tab) {
      display: none;
    }

    &-icon {
      width: 40px;
    }

    &-text {
      font-size: rem(13px);
      font-weight: $medium;
      text-align: right;
      letter-spacing: 0.1em;
      padding-right: 0.5rem;
    }

    a {
      color: $color-blue;
      text-decoration: none;
    }
  }

  &-language {
    position: relative;
    text-align: center;

    &--tab {
      display: none;

      @include view-at(tab) {
        display: block;
        position: absolute;
        top: 13px;
        right: 50px;

      }
    }

    &-icon {
      width: 18px;
    }

    &-text {
      font-size: rem(10px);
      letter-spacing: 0;
      display: block;
      color: $color-text;
    }

    &-list {
      display: block;
      width: 100px;
      background-color: $color-white;
      padding: 6px 17px;
      position: absolute;
      top: 28px;
      left: -37px;
      z-index: 100;
      transition: opacity 0.3s, visibility 0s 0.3s;
      opacity: 0;
      visibility: hidden;

      a {
        color: $color-text;
        font-size: rem(14px);
        line-height: rem(28px);
        font-weight: $bold;
        text-decoration: none;
      }

      &.visible {
        visibility: visible;
        opacity: 1;
        transition-delay: 0s;
      }
    }
  }
}
