.privacy {
  &-h{
    font-size: rem(16px);
    line-height: rem(22.5px);
    font-weight:$bold;
    color:$color-blue-dark;
    margin-bottom:10px;

    @include view-at(tab) {
      font-size: rem(16px);
      line-height: rem(22.5px);
    }
  }

  &-list{
    li{
      margin-bottom: 0;
      line-height: rem(22.5px);
      @include view-at(tab) {
        line-height: rem(22.5px);
      }
    }
    &--note li{
      padding-left: 1.2em;
      margin-bottom: 0;
      line-height: rem(22.5px);
      position: relative;
      @include view-at(tab) {
        line-height: rem(22.5px);
      }
      &::before {
        content: '＊';
        display: inline-block;
        position: absolute;
        top: 0px;
        left: 0;
      }
    }
    &--caption li{
      margin-bottom:0;
      font-size: rem(11px);
      line-height: rem(17px);
    }
  }

  &-panel{
    background:$color-gray-snow;
    height: 100%;

    &-h {
      font-size: rem(18px);
      line-height: rem(32px);
      font-weight: $bold;
      padding-bottom: 6px;
      border-bottom: 1px solid $color-text;
      margin-bottom: 20px;
      color:$color-blue;

      @include view-at(tab) {
        font-size: rem(15px);
        line-height: rem(27px);
      }
    }

    &-contents{
      padding: 40px 30px;
      letter-spacing: 0;
      width: 100%;

      &-item{
        display: flex;
      }
    }
  }

  &-table{
    width:100%;
    border-collapse: collapse;

    &-type {
      background-color: $color-blue;
      color: $color-white;
      text-align: center;
      font-size: rem(16px);
      padding: 9px;
      width: 50%;

      @include view-at(sp) {
        display: block;
        width: 100%;
      }
    }

    &-period {
      background-color: $color-blue-dark;
      color: $color-white;
      text-align: center;
      font-size: rem(16px);
      padding: 9px;
      width: 50%;

      @include view-at(sp) {
        display: none;
      }
    }

    thead th,
    tbody td{
      border: 2px solid $color-white;

      @include view-at(sp) {
        border: 0;
      }
    }

    tbody tr {
      background-color: $color-gray-snow;

      @include view-at(sp) {
        border: 2px solid $color-white;
        background-color: $color-blue-light;
      }
    }

    tbody td {
      padding: 30px;
      font-size: rem(14px);
      line-height: rem(25px);
      vertical-align: top;
      letter-spacing: 0.05em;

      @include view-at(sp) {
        display: block;
        padding: 30px 15px 15px;
      }

      &:last-child {
        @include view-at(sp) {
          padding: 0 15px 30px;
        }
      }
    }

    &-toggle {
      display: none;

      @include view-at(sp) {
        display: flex;
        align-items: center;
        color: $color-white;
        width: 100%;
        padding: 12px 15px 11px;
        margin-bottom: 0;
        position: relative;
        font-size: rem(13px);
        line-height: 1;
        font-weight: $bold;
        background-color: $color-blue-dark;

        &::before {
          content: '';
          display: inline-block;
          position: absolute;
          top: 8px;
          right: 10px;
          font-weight: $normal;
          width: 21px;
          height: 21px;
          border-radius: 50%;
          background-color: $color-white;
        }

        &::after {
          content: '\e901';
          font-family: 'fa';
          font-weight: 300;
          display: inline-block;
          position: absolute;
          top: 12px;
          right: 15px;
          color: $color-blue;
        }

        &.active::after {
          content: '\e900';
          font-family: 'fa';
          font-weight: 300;
          display: inline-block;
          position: absolute;
          top: 12px;
          right: 15px;
        }
      }
    }

    &-panel {
      @include view-at(sp) {
        padding: 15px;
        background-color: $color-gray-snow;
        display: none;

        &.visible {
          display: block;
        }
      }
    }
  }

  &-contact {
    font-size: rem(14px);
    @include view-at(sp) {
      font-size: rem(13px);
    }

    dt {
      display: inline-block;
      font-weight: $bold;
      width: 25%;
      padding: 5px 0;

      @include view-at(sp) {
        display: block;
        width: 100%;
        padding: 3px 0;
      }
    }

    dd {
      display: inline-block;
      width: 60%;
      padding: 5px 0;

      @include view-at(sp) {
        display: block;
        width: 100%;
        line-height: rem(24px);
      }
    }
  }

}