.p-cta {
  &-text {
    font-size: rem(18px);
    font-weight: $bold;
    letter-spacing: 0.15em;
  }

  &-rate {
    font-size: rem(54px);
    line-height: rem(92px);
    font-weight: $bold;
    color: $color-red;

    @include view-at(tab) {
      font-size: rem(30px);
      line-height: rem(40px);
    }
  }

  &-percent {
    font-size: rem(35px);
    line-height: rem(60px);
    font-weight: $bold;
    letter-spacing: 0.15em;
    margin-left: 0.1em;

    @include view-at(tab) {
      font-size: rem(24px);
      line-height: rem(32px);
    }
  }

  &-tilde {
    font-size: rem(43px);
    line-height: rem(74px);
    font-weight: $bold;
    letter-spacing: 0.15em;

    @include view-at(tab) {
      font-size: rem(32px);
      line-height: rem(38px);
    }
  }

  &-year {
    font-size: rem(34px);
    line-height: rem(58px);
    font-weight: $bold;
    color: $color-blue-dark;
    letter-spacing: 0;

    @include view-at(tab) {
      font-size: rem(28px);
      line-height: rem(32px);
    }
  }

  &-contact {
    display: flex;
    justify-content: space-between;
    padding: 35px 50px;
    border: 0.5px solid #707070;
    align-items: center;

    @include view-at(tab) {
      display: block;
      padding: 30px 20px;
    }

    &--white {
      background-color: $color-white;
    }

    &--blue {
      background-color: $color-blue-light;
    }

    &-tel {
      flex-shrink: 0;
      padding-right: 45px;

      @include view-at(tab) {
        text-align: center;
        padding-right: 0;
      }
    }

    &-form {
      border-left: 1px solid #707070;
      padding: 19px 0 19px 50px;

      &--article {
        border-left: 0;
        padding: 0;
        width: 100%;
      }

      @include view-at(tab) {
        border-left: 0;
        padding-left: 0;
        padding-bottom: 0;
      }

      &-text {
        font-size: rem(17px);
        font-weight: $bold;
        margin-bottom: 9px;

        @include view-at(tab) {
          display: none;
        }
      }
    }

    &-title {
      font-size: rem(20px);
      line-height: rem(24px);
      letter-spacing: 0.05em;
      font-weight: $bold;
      margin-bottom: 5px;

      &--article {
        font-size: rem(17px);
      }

      @include view-at(tab) {
        margin-bottom: 5px;
        font-size: rem(15px);
        letter-spacing: 0;
        margin-bottom: 14px;
      }
    }

    &-number {
      font-size: rem(64px);
      font-weight: $bold;
      margin-top: 1px;
      letter-spacing: 0.06em;
      margin-left: 1px;
      margin-bottom: 5px;

      &--article {
        font-size: rem(40px);
      }

      @include view-at(tab) {
        font-size: rem(35px);
      }
    }

    &-icon {
      width: 68px;
      margin-right: 10px;

      &--article {
        width: 43px;
      }

      @include view-at(tab) {
        width: 37px;
      }
    }

    &-text {
      font-size: rem(15px);
      font-weight: $medium;
      text-align: right;
      padding-right: 0.5rem;
      line-height: rem(22px);

      @include view-at(tab) {
        text-align: right;
        font-size: rem(12px);
      }
    }

    &-mail {
      background-color: $color-navy;
      display: flex;
      width: 100%;
      height: 84px;
      color: $color-white;
      font-size: rem(22px);
      text-align: center;
      align-items: center;
      justify-content: center;
      margin-left: 0;
      margin-right: 20px;
      transition: all 0.3s;
      border: 1px solid $color-navy;

      &--article {
        margin-right: 0;
        margin-top: 13px;
      }

      @include view-at(tab) {
        margin-left: 0;
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: rem(20px);
      }

      &:hover {
        background-color: $color-white;
        color: $color-navy;
        opacity: 1;

        &::before {
          background-image: url(/images/common/icon-mail-inverse.png);
        }
      }

      &::before {
        content: '';
        display: inline-block;
        width: 29px;
        height: 20px;
        margin-right: 10px;
        background: transparent url(/images/common/icon-mail.png) center center no-repeat;
        background-size: contain;
      }
    }

    &--blue {
      .p-cta-contact-mail {
        margin-left: 0;

        @include view-at(tab) {
          margin-left: 0;
        }
      }
    }

    &-reserve {
      background-color: $color-red-dark;
      display: flex;
      width: 100%;
      height: 84px;
      color: $color-white;
      font-size: rem(22px);
      text-align: center;
      align-items: center;
      justify-content: center;
      transition: all 0.3s;
      border: 1px solid $color-red-dark;

      @include view-at(tab) {
      }

      &:hover {
        background-color: $color-white;
        color: $color-red-dark;
        opacity: 1;

        &::before {
          background-image: url(/images/common/icon-reserve-inverse.png);
        }
      }

      &::before {
        content: '';
        display: inline-block;
        width: 31px;
        height: 31px;
        margin-right: 10px;
        background: transparent url(/images/common/icon-reserve.png) center center no-repeat;
        background-size: contain;
      }
    }

    &-button {
      width: 28px;
      margin-right: 10px;
    }
  }

  &-mail {
    max-width: 450px;
    margin: 0 auto;
  }
}