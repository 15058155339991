/* --------------------------
トピックス
-------------------------- */
.p-topics{
  &-list{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -15px;

    &-item{
      padding: 15px;
      width:calc(100% / 4);

      @include view-at(tab){
        width: 50%;
      }

      @include view-at(sp){
        width:50%;
      }

      &--circle{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
      }

      a{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 210px;
        height: 210px;
        background: $color-white;
        border-radius: 50%;
        margin: 0 auto;

        @include view-at(sp){
          width: 140px;
          height: 140px;
        }
      }

      &.limited{
        display: none;
      }
    }
  }
}