@charset "UTF-8";

.business{
  &-breadcrumb{

    &.c-breadcrumb--header{
      background: transparent;

      a{
        color:$color-white;
      }

      .c-breadcrumb-list{

        >li{
          color:$color-white;
        }

      }
    }

  }


  background: radial-gradient(ellipse farthest-side at top 15% left 35%, #6dc4f2 0%, #005cad 100%);
  position: relative;
  top:0;
  left: 0;

  .c-h1{
    color:$color-white;

    &::before,&::after{
      background: $color-white;
    }
  }

  &-panel{
    background:$color-white;
    height: 100%;

    &-contents{
      padding: 20px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      p {
        letter-spacing: 0.05em;
        font-feature-settings: "palt";
      }
    }

    &-h{
      color:$color-blue-dark;
      font-size:24px;
      letter-spacing: 0.2em;
      font-weight: $bold;
      text-align: center;
      margin-bottom: 20px;
    }

    &-list{
      display: flex;
      justify-content: space-between;
      margin: -5px;

      @include view-at(tab){
        flex-wrap: wrap;
      }

      &-item{
        padding: 5px;
        width: 100%;

        a{
          display: block;
          width: 100%;
          text-align: center;
          background: url(../../images/business/business-panel.png);
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          border:1px solid $color-gray;
          padding: 20px 10px;
          height: 100%;
        }

      }
    }

    &-area{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      &-title{
        font-size: rem(15px);
        line-height: 1.5;
        color:$color-blue-dark;
        font-weight: $bold;
        margin-bottom: .8em;
        letter-spacing: 0.05em;
        font-feature-settings: "palt";
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-description{
        color:$color-black;
        text-align: left;
        margin-bottom: 0;
        font-size: rem(12px);
        line-height: 1.5;
      }

      &-link{
        font-size: rem(12px);
        position: relative;
        padding-right: 15px;
        display: inline-block;
        padding-bottom: 5px;
        border-bottom:1px solid $color-blue;

        &::after {
          content: '';
          width: 8px;
          height: 8px;
          border-top: 2px solid $color-blue;
          border-right: 2px solid $color-blue;
          transform: rotate(45deg);
          display: inline-block;
          position: absolute;
          top: calc(50% - 6px);
          right: 2px;
          transition: all 0.3s;
        }

        &:hover {
          opacity: 1;

          &::after {
            transform: rotate(45deg) translate(2px, -2px);
          }

        }

      }

    }
  }

}