.access {
  &-detail {
    display: flex;
    align-items: center;
    background: $color-blue-light;

    @include view-at(sp) {
      flex-direction: column;
    }

    &-item {
      &--map {
        position: relative;
        display: inline-block;
        border: solid 1px #555;
        box-sizing: border-box;
        background: $color-white;

        @include view-at(pc) {
          flex-basis: 540px;
        }
        &::before {
          content: "";
          position: absolute;
          top: 50%;
          right: -24px;
          margin-top: -12px;
          border: 12px solid transparent;
          border-left: 12px solid #fff;
          z-index: 2;
          @include view-at(sp) {
            top: auto;
            right: auto;
            bottom: -24px;
            left: 50%;
            margin-top: 0px;
            margin-left: -12px;
            border: 12px solid transparent;
            border-top: 12px solid #fff;
            z-index: 2;
          }
        }
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          right: -27px;
          margin-top: -13px;
          border: 13px solid transparent;
          border-left: 13px solid #555;
          z-index: 1;
          @include view-at(sp) {
            top: auto;
            right: auto;
            bottom: -27px;
            left: 50%;
            margin-top: 0px;
            margin-left: -13px;
            border: 13px solid transparent;
            border-top: 13px solid #555;
            z-index: 1;
          }
        }
      }
      &-contents {
        padding: 40px 40px 40px 50px;
      }
    }
  }
  &-link {
    position: relative;
    padding-right: 15px;
    display: inline-block;
    &::after {
      content: "";
      width: 8px;
      height: 8px;
      border-top: 2px solid $color-blue;
      border-right: 2px solid $color-blue;
      transform: rotate(45deg);
      display: inline-block;
      position: absolute;
      top: calc(50% - 4px);
      right: 2px;
      transition: all 0.3s;
    }

    &:hover {
      opacity: 1;

      &::after {
        transform: rotate(45deg) translate(2px, -2px);
      }
    }
  }
}
