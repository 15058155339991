/* --------------------------
特徴
-------------------------- */
.p-feature{
  &-panel{
    background: $color-blue-light;
    margin: 10px 0;
    &-contents{
      padding: 10px 35px;
      &--horizontal{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 -20px;
        @include view-at(sp){
          flex-direction: column;
          margin: -20px 0;
          justify-content: center;
        }
      }
      &-item{
        padding: 0 20px;
        width: 100%;
        @include view-at(sp){
          padding: 20px 0;
        }
        &--icon{
          flex-basis: 220px;
          flex-shrink: 0;
          @include view-at(sp){
            flex-basis: 100%;
            width: 100%;
          }
        }
        &--circle{
          background: $color-white;
          height: 220px;
          width: 220px;
          border-radius: 50%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          @include view-at(sp){
            margin: 0 auto;
          }
        }
        &--description{
          padding-top: 15px;
          font-size: 0.75rem;
          line-height: 1.125rem;
          color:$color-blue-dark;
        }
      }
    }
  }
  &-link{
    &-list{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: -15px;
      @include view-at(sp){
        margin: 0 -15px;
      }
      &-item{
        width:calc(100% / 2);
        padding: 15px;
        @include view-at(sp){
          width:100%;
        }
        &--bg{
          background: radial-gradient(ellipse farthest-side at top 15% left 35%, #6dc4f2 0%, #005cad 100%);
        }
        &-wrapper{
          padding: 13px 15px 15px;
        }
        a{
          display: block;
          color: $color-white;
          position: relative;
        }
      }
      &-tag{
        background: $color-blue-dark;
        color:$color-white;
        padding: 9px;
        font-size:0.875rem;
        position: absolute;
        top:0;
        left: 0;
        z-index: 1;
      }
    }
    &-button{
      display: flex;
      justify-content: space-between;
      margin: 0 -10px;
      width:100%;
      @include view-at(sp){
        display: block;
        margin: 0;
      }
      &-item{
        padding: 0 10px;
        img{
          width:100%;
        }
        &--description{
          width:55%;
          flex-shrink: 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          position: relative;
          @include view-at(sp){
            width:100%;
            flex-shrink: unset;
          }
          &::before{
            content: '';
            position: absolute;
            bottom:0;
            right:10px;
            background: $color-white;
            border-radius: 50%;
            width: 32px;
            height: 32px;
          }
          &::after{
            content: '';
            width: 8px;
            height: 8px;
            border-top:2px solid $color-blue;
            border-right: 2px solid $color-blue;
            transform: rotate(45deg);
            display: inline-block;
            position: absolute;
            bottom: calc(16px - 4px);
            right: 24px;
          }
        }
      }
    }
  }
}