html {
  font-size: 16px;
}

body {
  font-family: "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", Lato, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN W3", HiraKakuProN-W3, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, sans-serif;
  font-weight: 300;
  color: $color-text;
  font-size: rem(16px);
  letter-spacing: 0.05em;
  @media all and (-ms-high-contrast:none) {
    font-family: "メイリオ", Meiryo, "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", Lato, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN W3", HiraKakuProN-W3, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", sans-serif;
  }
}

.ch {
  font-family: "微软雅黑","Microsoft YaHei","Microsoft JhengHei UI", "SimSun", "SimHei", sans-serif;
}

a {
  color: $color-blue;
  text-decoration: none;

  &:hover {
    opacity: 0.6;
  }
}

p {
  margin-bottom: 1em;
  line-height: rem(26px);

  &:last-child {
    margin-bottom: 0;
  }

  @include view-at(sp) {
    line-height: nth($line-height-sp, 5);
  }
}

img {
  width: auto;
  height: auto;
  max-width: 100%;
}

.main {
  padding-top: 158px;

  @include view-at(tab) {
    padding-top: 51px;
  }
}
