/* --------------------------
パンくずナビ
-------------------------- */

.c-breadcrumb {
  a {
    text-decoration: underline;
  }

  &--header {
    background-color: $color-blue-light;
    color: $color-text;

    @include view-at(sp) {
      display: none;
    }

    a {
      color: $color-text;
    }
  }

  &--footer {
    background-color: $color-blue-dark;
    color: $color-white;
    margin-top: 80px;

    a {
      color: $color-white;
    }
  }

  &-list {
    list-style: none;
    margin: 0;
    padding: 12px 0;
    display: flex;
    flex-wrap: wrap;

    @include view-at(sp) {
      padding: 8px 0;
    }

    li {
      font-size: rem(11px);
      line-height: 1.5;

      @include view-at(sp) {
        line-height: 1.5;
      }

      &::before {
        content: '>';
        padding: 0 0.5em;
      }

      &:first-child {
        &::before {
          display: none;
        }
      }
    }
  }
}
