/* --------------------------
ボタン
-------------------------- */

.c-button {
  display: inline-block;
  width: auto;
  border: 1px solid $color-blue;
  padding: 17px 20px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  color: $color-blue;
  background-color: $color-white;
  border-radius: 25px;
  position: relative;
  line-height: 1;
  transition: color 0.3s, background-color 0.3s;
  font-size: rem(15px);
  min-width: 250px;

  &:hover {
    color: $color-white;
    background-color: $color-blue;
    text-decoration: none;
    opacity: 1;
  }

  &--square {
    color: $color-text;
    border-radius: 3px;
    border: 0.5px solid $color-black;

    &:hover {
      color: $color-text;
      background-color: $color-white;
      text-decoration: none;
      opacity: 0.6;
    }
  }

  &--external {
    padding: 15px 50px 15px 35px;
    &::after {
      position: absolute;
      top: 50%;
      right: 15px;
      margin-top: -7px;
      display: inline-block;
      font-family: 'fa';
      content: '\f360';
      font-weight: 900;
    }
  }

  &--arrow-left {
    padding: 17px 20px 17px 35px;

    &::after {
      content: '';
      width: 8px;
      height: 8px;
      border-top: 2px solid $color-blue;
      border-right: 2px solid $color-blue;
      transform: rotate(225deg);
      display: inline-block;
      position: absolute;
      top: calc(50% - 4px);
      left: 15px;
      transition: all 0.3s;
    }

    &:hover {
      opacity: 1;

      &::after {
        border-color: $color-blue-dark;
        transform: rotate(225deg) translate(2px, -2px);
      }
    }
  }

  &--arrow-right {
    padding: 17px 35px 17px 20px;

    &::after {
      content: '';
      width: 8px;
      height: 8px;
      border-top: 2px solid $color-blue;
      border-right: 2px solid $color-blue;
      transform: rotate(45deg);
      display: inline-block;
      position: absolute;
      top: calc(50% - 4px);
      right: 15px;
      transition: all 0.3s;
    }

    &:hover {
      opacity: 1;

      &::after {
        border-color: $color-blue-dark;
        transform: rotate(45deg) translate(2px, -2px);
      }
    }
  }

  &--arrow-down {
    padding: 17px 20px 28px 20px;
    min-width: 128px;
    &::after {
      content: '';
      width: 8px;
      height: 8px;
      border-top: 2px solid $color-blue;
      border-right: 2px solid $color-blue;
      transform: rotate(135deg);
      display: inline-block;
      position: absolute;
      bottom: 12px;
      left: calc(50% - 4px);
      transition: all 0.3s;
    }

    &:hover {
      opacity: 1;

      &::after {
        border-color: $color-blue-dark;
        transform: rotate(135deg) translate(2px, -2px);
      }
    }
  }

  &--internal {
    padding: 15px 50px 15px 35px;
    &::after {
      position: absolute;
      top: 50%;
      right: 15px;
      margin-top: -7px;
      display: inline-block;
      font-family: 'fa';
      content: '\f105';
      font-weight: 900;
    }
  }

  &--primary {
    background-color: $color-blue;
    color: $color-white;

    &:hover {
      color: $color-blue;
      background-color: $color-white;
    }
  }

  &--gradient {
    background: linear-gradient(90deg, #008ddd 0%, #004d8f 100%);
    color: $color-white;

    &:hover {
      color: $color-blue;
      background: $color-white;
    }
  }

  &--action {
    background-color: $color-blue;
    color: $color-white;
    font-size: nth($list: $font-size, $n: 3);
    padding: 20px 45px;
    min-width: 350px;
    letter-spacing: 0.1em;

    @include view-at(sp) {
      padding: 15px;
      width: 100%;
    }
    &::after {
      margin-top: -10px;
    }
  }

  &--disabled {
    pointer-events: none;
    background-color: $color-gray;
    border-color: $color-gray;
    color: $color-white;
  }

  &--large {
    min-width: 350px;

    @include view-at(sp) {
      min-width: auto;
      width: 100%;
    }
  }

  &--small {
    min-width: auto;
  }
}
