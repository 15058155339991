.p-changelang {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border:1px solid $color-blue;
  border-radius: 50px;
  padding: 5px 0;
  max-width: 790px;
  margin: 0 auto;

  @include view-at(sp){
    flex-direction: column;
    border-radius: 5px;
  }

  &-item{
    width:100%;
    padding: 0 5px;
    border-left:1px solid $color-black;

    @include view-at(sp){
      border-left:none;
    }

    a{
      display: block;
      width: 100%;
      padding: 10px 0;
      transition: all 0.3s ease-in-out;

      @include view-at(sp){
        padding: 15px 0;
      }

      &:hover{
        background: $color-blue-light;
        border-radius: 50px;
      }
    }

    &--select{
      a{
        background: $color-blue-light;
        border-radius: 50px;
        pointer-events: none;
      }
    }
  }

  &--label{
    flex-basis: 200px;
    flex-shrink: 0;

    &::before{
      content:'';
      display: inline-block;
      width:15px;
      height: 15px;
      background-image: url(/images/common/icon-language.png);
      background-size: contain;
      vertical-align: bottom;
      margin-right: 5px;
    }
    
    @include view-at(sp){
      flex-basis: auto;
      padding: 10px 0;
    }
  }
}