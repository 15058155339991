/* --------------------------
フッター
-------------------------- */

.p-footer {
  background-color: $color-white;
  padding: 35px 0 0;

  @include view-at(tab) {
    padding: 0 0 47px;
  }

  &-pagetop {
    position: absolute;
    top: 0;
    right: 0;
    width: 58px;
    height: 58px;

    @include view-at(tab) {
      top: -95px;
      right: 15px;
      width: 40px;
      height: 40px;
    }
  }

  &-floating {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 240px;
    height: 210px;
    display: none;

    &--loan {
      bottom: 120px;
    }

    &--en {
      display: none !important;
    }

    @include view-at(tab) {
      bottom: 57px;
      width: 305px;
      height: 81px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }

    &-button {
      position: absolute;
      top: -15px;
      right: 0px;
      display: block;
      width: 30px;
      height: 30px;
      background-color: rgba(0,0,0,.8);
      border: 0;
      border-radius: 50%;
      font-size: 0;
      cursor: pointer;

      @include view-at(tab) {
        top: -20px;
        right: 5px;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 7px;
        width: 16px;
        height: 2px;
        background-color: #fff;
        transform: rotate(45deg);
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 7px;
        width: 16px;
        height: 2px;
        background-color: #fff;
        transform: rotate(-45deg);
      }
    }
  }

  &-container {
    width: $contents-width;
    margin: 0 auto;
    position: relative;

    @include view-at(pc) {
      padding: 0 $contents-padding;
    }

    @include view-at(tab) {
      width: 100%;
      padding: 40px 80px 0;
    }

    @include view-at(sp) {
      width: 100%;
      padding: 0;
    }
  }

  &-contents {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
    letter-spacing: 0;

    @include view-at(tab) {
      display: block;
    }

    &-left {
      width: 66.6666%;

      @include view-at(tab) {
        width: 100%;
      }
    }

    &-right {
      width: 33.3333%;
      border-left: 0.5px solid #808080;
      padding-left: 49px;
      padding-bottom: 80px;

      @include view-at(tab) {
        width: 100%;
        border: 0;
        padding: 0;
      }
    }
  }

  &-section {
    margin: 20px 0;

    @include view-at(tab) {
      margin: 0;
    }
  }

  &-h3 {
    color: $color-blue;
    font-size: rem(23px);
    font-weight: $bold;
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    @include view-at(tab) {
      width: 100%;
      padding: 17px 12px;
      margin-bottom: 0;
      border-bottom: 1px solid $color-border;
      position: relative;
      font-size: rem(20px);

      &::before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 23px;
        right: 15px;
        font-weight: $normal;
        width: 23px;
        height: 23px;
        border-radius: 50%;
        background-color: $color-blue;
      }

      &::after {
        content: '\e901';
        font-family: 'fa';
        font-weight: 300;
        display: inline-block;
        position: absolute;
        top: 26px;
        right: 19px;
        color: $color-white;
      }

      &.active::after {
        content: '\e900';
        font-family: 'fa';
        font-weight: 300;
        display: inline-block;
        position: absolute;
        top: 26px;
        right: 19px;
      }
    }
  }

  &-h4 {
    font-size: rem(19px);
    line-height: rem(33px);
    font-weight: $bold;
    position: relative;
    padding-left: 20px;
    margin-bottom: 12px;

    @include view-at(tab) {
      font-size: rem(18px);
      line-height: rem(22px);
      padding: 15px;
      margin: 0;
      border-bottom: 1px solid $color-border;
    }

    &::before {
      content: '';
      width: 10px;
      height: 10px;
      border-top: 1px solid $color-blue;
      border-right: 1px solid $color-blue;
      transform: rotate(45deg);
      display: inline-block;
      position: absolute;
      top: 10px;
      left: 0;

      @include view-at(tab) {
        display: none;
      }
    }

    a {
      color: $color-text;
    }
  }

  &-icon {
    width: 37px;
    margin-right: 5px;
  }

  &-nav {
    @include view-at(tab) {
      display: none;

      &.visible {
        display: block;
      }
    }
  }

  &-list {
    margin-bottom: 25px;

    @include view-at(tab) {
      margin: 0;
    }

    > li {
      font-size: rem(14px);
      line-height: rem(20px);
      font-weight: $bold;
      position: relative;
      padding-left: 15px;
      margin-bottom: 20px;

      @include view-at(tab) {
        padding: 0;
        margin: 0;
        font-weight: $normal;
        border-bottom: 1px solid $color-border;
      }

      &::before {
        content: '';
        width: 7px;
        height: 7px;
        border-top: 1px solid $color-blue;
        border-right: 1px solid $color-blue;
        transform: rotate(45deg);
        display: inline-block;
        position: absolute;
        top: 6.5px;
        left: 0;
        transition: all 0.3s;

        @include view-at(tab) {
          display: none;
        }
      }

      &:hover {
        &::before {
          border-color: $color-blue-dark;
          transform: rotate(45deg) translate(2px, -2px);
        }
      }

      > a {
        color: $color-text;

        @include view-at(tab) {
          display: block;
          padding: 15px 40px 15px 15px;

          &::after {
            content: '';
            width: 8px;
            height: 8px;
            border-top: 1px solid $color-gray-dark;
            border-right: 1px solid $color-gray-dark;
            transform: rotate(45deg);
            display: inline-block;
            position: absolute;
            top: calc(50% - 4px);
            right: 20px;
          }
        }
      }
    }

    &-internal {
      margin-left: -15px;
      margin-top: 11.5px;

      @include view-at(tab) {
        margin: 0;
      }

      >li {
        font-size: rem(12px);
        line-height: rem(20px);
        position: relative;
        padding-left: 15px;
        margin-bottom: 10px;

        @include view-at(tab) {
          padding: 0;
          margin: 0;
          border-bottom: 1px solid $color-border;
          font-size: rem(14px);
        }

        &::before {
          content: '';
          width: 7px;
          height: 7px;
          border-top: 1px solid $color-blue;
          border-right: 1px solid $color-blue;
          transform: rotate(45deg);
          display: inline-block;
          position: absolute;
          top: 6.5px;
          left: 0;
          transition: all 0.3s;

          @include view-at(tab) {
            display: none;
          }
        }

        &:hover {
          &::before {
            border-color: $color-blue-dark;
            transform: rotate(45deg) translate(2px, -2px);
          }
        }

        a {
          color: #484B44;
          font-weight: $normal;

          @include view-at(tab) {
            display: block;
            padding: 15px;

            &::after {
              content: '';
              width: 8px;
              height: 8px;
              border-top: 1px solid $color-gray-dark;
              border-right: 1px solid $color-gray-dark;
              transform: rotate(45deg);
              display: inline-block;
              position: absolute;
              top: calc(50% - 4px);
              right: 20px;
            }
          }
        }
      }
    }
  }

  &-company {
    border-top: 1px solid #808080;
    padding: 25px 0;

    @include view-at(tab) {
      border: 0;
      padding: 35px 0 15px;
    }

    @include view-at(sp) {
      border: 0;
      padding: 35px 20px 15px;
    }
  }

  &-logo {
    width: 356px;

    @include view-at(tab) {
      width: 100%;
    }
  }

  &-loan {
    @include view-at(sp) {
      border: 1px solid $color-gray;
      padding: 15px;
      margin-bottom: 20px;
    }


    &-icon {
      width: 156px;
      float: left;
      margin-right: 20px;

      @include view-at(tab) {
        width: 200px;
      }

      @include view-at(sp) {
        float: none;
        width: 100%;

        img {
          width: 100%;
        }
      }
    }

    &-description {
      font-size: rem(10px);
      line-height: rem(18px);
    }
  }

  &-copyright {
    font-size: 0.825rem;
    line-height: rem(22px);
    color: $color-white;
    background-color: $color-blue-dark;
    padding: 5px;
    text-align: center;

    @include view-at(tab) {
      font-size: rem(10px);
      line-height: rem(13px);
      letter-spacing: 0;
    }
  }

  &-grouplink {
    padding: 40px 0;
    background-color: #f8f8f8;

    @include view-at(sp) {
      padding: 30px 20px;
    }

    h3 {
      font-size: rem(18px);
      font-weight: $bold;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
      margin: 30px 0;

      @include view-at(tab) {
        margin: 20px 0;
        display: block;
      }

      li {
        padding: 0 15px;
        font-size: rem(13px);
        line-height: 1.6;
        position: relative;

        @include view-at(tab) {
          padding: 0 0 0 15px;
          font-size: rem(12px);
          line-height: 2.2;
        }

        &::before {
          content: '';
          width: 7px;
          height: 7px;
          border-top: 1px solid $color-blue;
          border-right: 1px solid $color-blue;
          transform: rotate(45deg);
          display: inline-block;
          position: absolute;
          top: 6.5px;
          left: 0;
          transition: all 0.3s;

          @include view-at(tab) {
            top: 9.5px;
          }
        }

        &:first-child {
          font-weight: $bold;
          width: 100%;
          flex-shrink: 0;
          font-size: rem(14px);
          padding-left: 0;
          margin-bottom: 5px;

          &::before {
            display: none;
          }
        }

        a {
          color: $color-text;
        }
      }
    }
  }
}
