@charset "UTF-8";

.company {
    .horizontal-list {
        display: flex;

        @include view-at(sp) {
            display: block;
        }

        &-column {
            margin-left: 100px;

            @include view-at(sp) {
                margin-left: 0;
                margin-bottom: 5px;
            }

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .circle {
        display: inline-block;
        width: 18px;
        height: 18px;
        background-color: #b1b1b1;
        border-radius: 9px;
        margin-right: 10px;
    }
}
