.financing{

  .p-financing_case-list-item a{
    &::before{
      top:auto;
      left: 50%;
      transform: rotate(135deg);
      bottom:25px;
      transition: 0.3s all;
    }

    &:hover{
      &::before{
        transform:rotate(135deg) translate(2px, -2px);
      }
    }

  }

  &-list{
    margin: -10px;

    &-item{
      padding: 10px;

      a{
        display: block;
        color:$color-black;
        font-size: 18px;
        font-weight: $bold;
        letter-spacing: .1rem;
        line-height: 1.6;
        padding: 35px 50px;
        background: $color-blue-light;
        border-radius: 5px;
        position: relative;

        @include view-at(sp){
          padding: 15px 15px 15px 30px;
        }

        &::before{
          position: absolute;
          top:calc(50% - 4px);
          left:30px;
          content: '';
          width:8px;
          height: 8px;
          display: inline-block;
          border-top: 2px solid $color-blue;
          border-right: 2px solid $color-blue;
          transform: rotate(45deg);

          @include view-at(sp){
            left: 10px;
          }
        }

        span{
          text-decoration: underline;
        }

      }

    }

  }

  &_case{
    &-h1 {
      font-size: rem(30px);

      @include view-at(sp) {
        font-size: rem(18px);
      }
    }

    &-section{
      margin-bottom: 80px;


      &-panel{
        background: $color-blue-dark;
        padding: 30px 65px;

        @include view-at(sp) {
          padding: 20px;
        }

        &-text{
          color:$color-white;
        }

        &-h{
          background: $color-blue;
          padding: 15px 30px;
          color:$color-white;

          @include view-at(sp) {
            padding: 20px;
          }
        }
      }
    }

    &-panel{
      background: $color-blue-light;
      border-left:60px solid $color-white;
      border-right:60px solid $color-white;

      @include view-at(sp) {
        border-left: 0;
        border-right: 0;
      }

      &--half{
        background: linear-gradient(transparent 50%, #e5f5ff 50%);
      }
    }

    &-intro{
      display: flex;
      justify-content: space-between;
      position: relative;
      background: radial-gradient(ellipse farthest-side at top 15% left 35%, #6dc4f2 0%, #005cad 100%);
      padding: 40px;

      @include view-at(sp) {
        display: block;
      }

      &-image{
        width: 30%;

        @include view-at(sp) {
          width: 100%;
        }

        img{
          width:100%;
          display: block;
        }
      }

      &-detail{
        width:70%;
        padding-left: 40px;
        color:$color-white;

        @include view-at(sp) {
          width: 100%;
        }

        &-row {
          display: flex;
          margin-bottom: 0;
        }

        &-title {
          flex-shrink: 0;
        }
      }
    }
  }
}









