/* --------------------------
Gナビ
-------------------------- */

.p-gnav {
  background-color: $color-blue;
  box-shadow: 0 3px 6px rgba(0,0,0,0.1);
  position: relative;

  @include view-at(tab) {
    display: none;
    margin: 0;
    position: absolute;
    top: 50px;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 80px;
    z-index: 1000;
    box-shadow: 0 2px 2px rgba(0,0,0,0.3);
    height: 100%;
    overflow-y: scroll;
    background-color: $color-white;


    &.visible {
      display: block;
    }
  }

  @include view-at(tab) {
    padding: 0;
  }

  &-list {
    max-width: 1366px;
    margin: 0 auto;
    display: flex;

    @include view-at(pc) {
      min-width: $contents-width;
    }

    @include view-at(tab) {
      flex-wrap: wrap;
      min-width: auto;
      width: 100%;
    }

    &-item {
      width: 100%;

      &.current {
        >a {
          background-color: $color-white;
          color: $color-blue;
          opacity: 1;
        }
      }

      @include view-at(pc) {
        &:hover {
          >a {
            background-color: $color-blue-light;
            color: $color-blue;
            opacity: 1;
          }

          .p-gnav-sub {
            visibility: visible;
            opacity: 1;
            transition-delay: 0s;
          }
        }
        &.js-close:hover {
          >a {
            background-color: $color-blue;
            color: $color-white;
            opacity: 1;
          }

          .p-gnav-sub {
            visibility: hidden;
            opacity: 0;
            transition-delay: 0s;
          }
        }
      }

      &:last-child {
        >a {
          border-right: 1px solid $color-white;
        }
      }

      >a {
        display: block;
        border-left: 1px solid $color-white;
        color: $color-white;
        font-size: rem(15px);
        line-height: 1;
        padding: 18px 10px;
        position: relative;
        text-decoration: none;
        text-align: center;

        @include view-at(tab) {
          color: $color-blue;
          background-color: $color-white;
          border: 0;
          border-bottom: 1px solid $color-border;
          text-align: left;
          padding: 19px 15px;

          &::after {
            content: '\e901';
            font-family: 'fa';
            font-weight: 300;
            display: inline-block;
            position: absolute;
            top: 20px;
            right: 15px;
          }

          &.active::after {
            content: '\e900';
            font-family: 'fa';
            font-weight: 300;
            display: inline-block;
            position: absolute;
            top: 20px;
            right: 15px;
          }
        }
      }
    }
  }

  &-sub {
    display: block;
    position: absolute;
    top: 51px;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 1366px;
    margin: 0 auto;
    background-color: $color-blue-light;
    box-shadow: 0 3px 3px rgba(0,0,0,0.1);
    transition: opacity 0.3s, visibility 0s 0.3s;
    opacity: 0;
    visibility: hidden;

    @include view-at(tab) {
      position: relative;
      top: 0;
      background-color: $color-white;
      height: auto;
      box-shadow: none;
      opacity: 1;
      visibility: visible;
      display: none;
    }


    &.visible {
      display: block;
    }

    &-container {
      padding: 30px 30px 26px;

      @include view-at(pc) {
        min-width: $contents-width;
      }

      @include view-at(tab) {
        display: block;
        min-width: auto;
        padding: 0;
      }
    }

    &-info {
      padding-right: 50px;

      @include view-at(mdpi) {
        width: 250px;
      }

      @include view-at(tab) {
        width: 100%;
        padding: 0;
      }

      &--salable {
        width: 50%;

        @include view-at(tab) {
          width: 100%;
        }
      }
    }

    &-title {
      color: $color-blue;
      font-size: rem(19px);
      font-weight: $bold;
      padding-left: 20px;
      position: relative;

      &::before {
        content: '';
        width: 10.5px;
        height: 10.5px;
        border-top: 1px solid $color-blue;
        border-right: 1px solid $color-blue;
        transform: rotate(45deg);
        display: inline-block;
        position: absolute;
        top: 8px;
        left: 0;
        transition: all 0.3s;

        @include view-at(tab) {
          display: none;
        }
      }

      &:hover {
        &::before {
          border-color: $color-blue-dark;
          transform: rotate(45deg) translate(2px, -2px);
        }
      }

      @include view-at(tab) {
        margin-bottom: 0;
        padding-left: 0;
        border-bottom: 1px solid $color-border;
        font-weight: $normal;
        font-size: rem(14px);

        a {
          display: block;
          padding: 17px 15px;
          color: $color-text;

          &::after {
            content: '';
            width: 8px;
            height: 8px;
            border-top: 1px solid $color-gray-dark;
            border-right: 1px solid $color-gray-dark;
            transform: rotate(45deg);
            display: inline-block;
            position: absolute;
            top: calc(50% - 4px);
            right: 20px;
          }
        }
      }
    }

    &-description {
      @include view-at(tab) {
        display: none;
      }
    }

    &-colmun {
      flex-shrink: 0;
      padding: 0 50px 0 20px;
      border-left: 1px solid #e5e5e5;

      @include view-at(mdpi) {
        flex-shrink: 1;
      }

      @include view-at(tab) {
        padding: 0;
        border: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    &-list {
      display: flex;
      flex-wrap: wrap;
      margin: -10px;

      @include view-at(tab) {
        display: block;
        margin: 0;
      }

      > li {
        width: 25%;
        padding: 10px;

        @include view-at(tab) {
          width: 100%;
          padding: 0;
        }

        &.small {
          a {
            font-size: rem(12px);
            letter-spacing: 0;
          }
        }

        @include view-at(pc) {
          @for $i from 1 through 9 {
            &.order-#{$i} {
              order: #{$i};
            }
          }

          &.color-red {
            a {
              &::after {
                content: '';
                display: block;
                width: 100%;
                height: 4px;
                background-color: #FF6767;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
              }
            }
          }

          &.color-green {
            a {
              &::after {
                content: '';
                display: block;
                width: 100%;
                height: 4px;
                background-color: #67FF7E;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
              }
            }
          }

          &.color-yellow {
            a {
              &::after {
                content: '';
                display: block;
                width: 100%;
                height: 4px;
                background-color: #FFD667;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
              }
            }
          }
        }

        a {
          display: flex;
          align-items: center;
          color: $color-blue;
          font-size: rem(15px);
          line-height: rem(19px);
          font-weight: $bold;
          background-color: $color-white;
          padding: 0 15px 0 38px;
          position: relative;
          box-shadow: 3px 3px 18px rgba(0,0,0,0.14);
          height: 54px;

          @include view-at(tab) {
            width: 100%;
            padding: 17px 33px 17px 15px;
            display: block;
            color: $color-text;
            font-weight: normal;
            box-shadow: none;
            border-bottom: 1px solid $color-text;
            font-size: rem(14px);
            line-height: rem(26px);
            height: auto;
          }

          &::before {
            content: '';
            width: 7px;
            height: 7px;
            border-top: 1px solid $color-blue;
            border-right: 1px solid $color-blue;
            transform: rotate(45deg);
            display: inline-block;
            position: absolute;
            top: 24px;
            left: 18px;
            transition: all 0.3s;

            @include view-at(tab) {
              border-color: $color-text;
              left: auto;
              right: 20px;
              top: 50%;
              width: 8px;
              height: 8px;
            }
          }

          &:hover {
            &::before {
              border-color: $color-blue-dark;
              transform: rotate(45deg) translate(2px, -2px);
            }
          }
        }
      }

      &-internal {
        margin-left: -15px;
        margin-top: 11.5px;

        @include view-at(tab) {
          display: none;
        }

        >li {
          font-size: rem(13px);
          line-height: rem(20px);
          position: relative;
          padding-left: 15px;
          margin-bottom: 5px;

          &::before {
            content: '';
            width: 7px;
            height: 7px;
            border-top: 1px solid $color-blue;
            border-right: 1px solid $color-blue;
            transform: rotate(45deg);
            display: inline-block;
            position: absolute;
            top: 6.5px;
            left: 0;
            transition: all 0.3s;
          }

          &:hover {
            &::before {
              border-color: $color-blue-dark;
              transform: rotate(45deg) translate(2px, -2px);
            }
          }

          a {
            color: $color-gray-dark;
            font-weight: $normal;
          }
        }
      }
    }

    &-close {
      margin: 0 10px;
      border-top: 1px solid #C6C6C6;
      text-align: center;
      padding: 15px;

      @include view-at(tab) {
        display: none;
      }

      a {
        font-size: rem(15px);
        font-weight: $bold;
        position: relative;
        padding-left: 18px;

        &::before {
          content: '';
          border-left: 1px solid $color-blue;
          transform: rotate(45deg) translate(4px, 4px);
          width: 19px;
          height: 19px;
          position: absolute;
          top: 0;
          left: 0;
          display: block;
        }

        &::after {
          content: '';
          border-bottom: 1px solid $color-blue;
          transform: rotate(45deg) translate(-5px, -5px);
          width: 19px;
          height: 19px;
          position: absolute;
          top: 0;
          left: 0;
          display: block;
        }
      }
    }
  }
}
