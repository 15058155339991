.search {
  $gray-snow: #f2f2f2;

  &-mv{
    background-image:url(/images/search/mv.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    max-height: 860px;
    height: 66vh;

    @include view-at(sp){
      max-height: 550px;
    }

    &-title{
      font-size:45px;
      line-height: 1.4;
      font-weight: $bold;
      margin-bottom: 25px;

      @include view-at(sp) {
        font-size: rem(38px);
      }
    }

    &-description{
      font-size: rem(22px);

      @include view-at(sp) {
        font-size: rem(18px);
      }
    }
    &-contents{
      display: flex;
      max-height: 860px;
      height: 66vh;
      justify-content: flex-start;
      align-items: center;

      @include view-at(sp){
        max-height: 550px;
      }
    }
  }

  &-condition-list {
    display: flex;
    flex-wrap: wrap;
    margin: -5px;

    li {
      width: 25%;
      padding: 5px;

      @include view-at(sp) {
        width: 50%;
      }

      a {
        display: block;
        background-color: $gray-snow;
        border: 1px solid $gray-snow;
        border-radius: 3px;
        font-size: rem(15px);
        font-weight: $bold;
        padding: 15px;
        color: $color-text;
        text-align: center;
        letter-spacing: 0.025em;

        @include view-at(sp) {
          padding: 15px 10px;
          height: 100%;
        }

        small {
          font-size: rem(13px);
        }

        &.selected {
          background-color: $color-blue-light;
          border-color: $color-blue;
        }
      }
    }
  }

  &-product {
    margin-bottom: 20px;

    &-header {
      background-color: $color-blue-light;
      padding: 25px 25px 20px;
      display: block;
      position: relative;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 34px;
        right: 25px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: $color-white;

        @include view-at(sp) {
          top: auto;
          bottom: 10px;
          right: 10px;
          width: 30px;
          height: 30px;
        }
      }

      &::after {
        content: '\e901';
        font-family: 'fa';
        font-weight: 300;
        display: block;
        position: absolute;
        top: 35px;
        right: 25px;
        width: 40px;
        height: 40px;
        font-size: rem(30px);
        text-align: center;
        line-height: 40px;
        letter-spacing: 0;

        @include view-at(sp) {
          font-size: rem(22px);
          top: auto;
          bottom: 10px;
          right: 10px;
          width: 30px;
          height: 30px;
          line-height: 30px;
        }
      }

      &.active {
        &::after {
          content: '\e900';
          font-family: 'fa';
          font-weight: 300;
          top: 35px;
          font-size: rem(30px);

          @include view-at(sp) {
            font-size: rem(22px);
            top: auto;
            bottom: 10px;
          }
        }
      }
    }

    &-name {
      font-size: rem(18px);
      font-weight: $bold;
      color: $color-text;
      line-height: rem(32px);
      margin-bottom: 1px;
    }

    &-label {
      display: inline-block;
      padding: 5px 9px 5px;
      font-size: rem(12px);
      line-height: 1;
      border-radius: 2px;
      border: 1px solid $color-blue;
      margin-bottom: 5px;
      font-feature-settings: "palt";

      small {
        font-size: rem(11px);
      }

      &--use {
        background-color: $color-white;
        color: $color-blue;
      }

      &--person {
        background-color: $color-blue;
        color: $color-white;
      }
    }

    &-contents {
      display: none;
      padding: 20px 0 20px;
      font-size: rem(15px);

      &.visible {
        display: block;
      }
    }
  }
}