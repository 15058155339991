
.wp-pagenavi{
  text-align: center;
  .pages{
    display: none;
  }
  span, a{
    /*数字部分の共通CSS　大きさなど*/
    display: inline-block;
    margin: 0 5px;
    padding: 5px 8px;
    text-decoration: none;

    &.current, &:hover{
      /*現在のページ*/
      color: $color-white;
      background: $color-green-dark;
    }
  }
}
