.loan{
  &-accent-color{
    color:$color-blue-dark;
  }

  &-h1 {
    position: absolute;
    top: vw(36);
    left: 0;
    right: 0;
    text-align: center;
    font-size: vw(60);
    font-weight: $bold;
    color: $color-blue;

    @include view-at(sp) {
      top: vw(70, 375);
      font-size: vw(36, 375);
    }

    small {
      font-size: vw(30);
      color: $color-text;
      display: block;
      margin-bottom: vw(10);
      font-feature-settings: "palt";

      @include view-at(sp) {
        font-size: vw(18, 375);
      }
    }
  }

  &-mv {
    text-align: center;
    position: relative;
    margin-bottom: 80px;

    @include view-at(sp) {
      margin-bottom: 80px;
    }

    &-description {
      position: absolute;
      bottom: -40px;
      text-align: center;
      font-weight: $bold;
      left: 0;
      right: 0;

      @include view-at(sp) {
        font-size: rem(14px);
        bottom: -80px;
        text-align: left;
        padding: 20px;
      }
    }

    img {
      width: 100%;
    }
  }

  &-overview {
    &-h2 {
      color: $color-text;
      font-size: rem(22px);
      font-weight: $bold;
      line-height: 1.5em;
      letter-spacing: 0.1em;
      margin-top: 100px;
      margin-bottom: 40px;
      background-color: $color-blue-light;
      padding: 16px 15px;
      text-align: center;

      @include view-at(sp) {
        font-size: rem(16px);
        padding: 10px;
      }
    }

    &-panel {
      padding: 25px 30px;
      height: 100%;

      @include view-at(sp) {
        margin: 0;
        padding: 15px;
        height: auto;
      }
    }

    &-title {
      text-align: center;
      font-size: rem(18px);
      font-weight: $bold;
      margin-bottom: 20px;

      @include view-at(sp) {
        margin-bottom: 15px;
      }
    }

    &-number {
      font-size: rem(30px);
    }
  }

  &-usage {
    &-list {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin: 45px auto;
      gap: 10px;
      max-width: 1050px;

      li {
        background-color: $color-blue-light;
        text-align: center;
        font-weight: $bold;
        font-size: rem(15px);
        line-height: 45px;
        height: 45px;
        width: 255px;

        @include view-at(sp) {
          width: calc(1/3*100% - (1 - 1/3)*10px);
          height: 50px;
          line-height: 16px;
          display: grid;
          place-content: center;
        }
      }
    }
  }

  &-advantage {
    &-q {
      position: relative;

      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 150px;
        height: auto;

        @include view-at(sp) {
          width: 77px;
        }
      }

      span {
        display: block;
        max-width: 665px;
        border-radius: 10px;
        padding: 30px 30px 25px;
        background-color: #F5F5F5;
        position: relative;
        margin-left: 180px;

        @include view-at(sp) {
          padding: 20px;
          margin-left: 100px;
        }

        &::before {
          content: '';
          display: block;
          border-right: 26px solid #F5F5F5;
          border-top: 12px solid transparent;
          border-bottom: 12px solid transparent;
          position: absolute;
          top: calc(50% - 12px);
          left: -26px;

          @include view-at(sp) {
            top: 20px;
          }
        }
      }
    }

    &-a {
      position: relative;

      img {
        position: absolute;
        right: 0;
        top: 0;
        width: 155px;
        height: auto;

        @include view-at(sp) {
          width: 77px;
          top: auto;
          bottom: 0;
        }
      }

      span {
        display: block;
        max-width: 665px;
        border-radius: 10px;
        padding: 30px 30px 25px;
        background-color: $color-blue-light;
        position: relative;
        margin: 25px 185px 0 auto;
        font-weight: $bold;

        @include view-at(sp) {
          margin-right: 100px;
          padding: 20px;
        }

        &::before {
          content: '';
          display: block;
          border-left: 26px solid $color-blue-light;
          border-top: 12px solid transparent;
          border-bottom: 12px solid transparent;
          position: absolute;
          top: calc(50% - 12px);
          right: -26px;

          @include view-at(sp) {
            top: auto;
            bottom: 20px;
          }
        }
      }
    }
  }

  &-simulation {
    &-panel {
      display: flex;
      justify-content: center;
      padding: 30px 50px;
      gap: 20px;

      @include view-at(sp) {
        padding: 20px 15px;
        gap: 10px;
        align-items: center;
        margin: 0;
      }
    }

    &-title {
      width: 200px;
      background-color: #fff;
      height: 200px;
      border-radius: 50%;
      display: grid;
      place-content: center;
      place-items: center;
      text-align: center;
      color: $color-blue;
      font-weight: $bold;
      line-height: rem(26px);

      @include view-at(sp) {
        width: 100px;
        height: 100px;
        font-size: rem(10px);
        line-height: 1.5;
        flex-shrink: 0;
      }

      img {
        width: 48px;
        margin-bottom: 5px;

        @include view-at(sp) {
          width: 28px;
        }
      }
    }

    &-table {
      width: 220px;
      border-top: 1px solid #707070;
      font-size: rem(14px);

      @include view-at(sp) {
        font-size: rem(13px);
      }

      tr {
        border-bottom: 1px solid #707070;
      }

      th {
        padding: 15px 0;
        width: 50%;
        color: #333;
        font-weight: $medium;

        @include view-at(sp) {
          padding: 10px 0;
          width: 53%;
        }
      }

      td {
        width: 50%;
        padding: 15px 0 15px 10px;
        color: $color-blue;
        font-weight: $bold;

        @include view-at(sp) {
          padding: 10px 0;
          width: 47%;
        }
      }
    }
  }

  &-border{
    border:30px solid;

    @include view-at(sp){
      border:none;
    }

    &--blue{
      border-color:$color-blue-light
    }

    &--white{
      border-color:$color-white;
    }
  }

  &-banner {
    max-width: 1366px;
    margin: 0 auto;

    img {
      width: 100%;
    }
  }

  &-h3 {
    color: $color-text;
    font-size: rem(17px);
    font-weight: $bold;
    line-height: 1.5em;
    letter-spacing: 0.1em;
    margin-bottom: 27px;
    background-color: $color-white;
    border-left: 12px solid $color-blue;
    padding: 16px 15px;

    @include view-at(sp) {
      font-size: rem(16px);
      padding: 10px;
    }
  }

  &-link{
    margin-top: 30px;
    font-size:15px;
    position: relative;
    padding-right: 15px;
    display: inline-block;
    border-bottom:1px solid $color-blue;
    padding-bottom:5px;

    &::after {
      content: '';
      width: 8px;
      height: 8px;
      border-top: 2px solid $color-blue;
      border-right: 2px solid $color-blue;
      transform: rotate(45deg);
      display: inline-block;
      position: absolute;
      top: calc(50% - 4px);
      right: 2px;
      transition: all 0.3s;
    }

    &:hover {
      opacity: 1;

      &::after {
        transform: rotate(45deg) translate(2px, -2px);
      }
    }
  }

  &-panel{
    background: url(/images/loan/loan/panel-link.png);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    border:1px solid $color-gray;

    &-contents{
      padding: 40px 65px;

      @include view-at(sp){
        padding: 25px 20px;
      }
    }
  }

  &-feature{

    &-title{
      font-size: 28px;
      font-weight: $bold;
      line-height: 2.2;
      margin: 30px 0;

      @include view-at(sp){
        font-size: 20px;
      }
    }

    &-list{
      display: flex;
      align-items: center;
      justify-content: center;
      margin: -30px;

      @include view-at(tab){
        flex-wrap: wrap;
      }

      @include view-at(sp){
        margin: -15px;
      }

      &-item{
        padding: 30px;
        width: calc(100% / 3);

        @include view-at(tab){
          width:50%;
        }

        @include view-at(sp){
          width:100%;
          padding: 15px;
        }
      }

    }

    &-contents{
      background: $color-white;
      background-size: cover;
      height: 100%;
      border-radius: 50%;
      height: 330px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      @include view-at(tab){
        height: 295px;
      }

      @include view-at(sp){
        height: 335px;
      }
    }

  }

  &-episode{

    &-arrow{
      border:1px solid $color-blue;
      border-radius: 50%;
      background: $color-white;
      height: 30px;
      width: 30px;
      position: absolute;
      top:50%;
      z-index: 100;

      &--left{
        left: 20px;

        &::before{
          position: absolute;
          top:calc(50% - 4px);
          left:10px;
          content:'';
          display: block;
          width:10px;
          height: 10px;
          border-left:2px solid $color-black;
          border-top:2px solid $color-black;
          transform: rotate(-45deg);
        }

      }

      &--right{
        right:20px;

        &::before{
          position: absolute;
          top:calc(50% - 4px);
          right:10px;
          content:'';
          display: block;
          width:10px;
          height: 10px;
          border-right:2px solid $color-black;
          border-top:2px solid $color-black;
          transform: rotate(45deg);
        }

      }

    }
    &-list{
      margin: -15px;
      position: relative;

      @include view-at(sp){
        margin: -20px;
      }

      &-item{
        padding: 15px;

        @include view-at(sp){
          &.slick-slide {
            transition: .3s ease;
          }
          &.slick-slide:not(.slick-current) {
            opacity: .5
          }
        }

        a{
          display: block;
          color:$color-white;
          position: relative;
          height: 100%;
          background: linear-gradient(90deg, #008ddd 0%, #004d8f 100%);

          &::before{
            position: absolute;
            bottom:0;
            right:0;
            content: '';
            display: block;
            border-style: solid;
            border-width: 0 0 40px 40px;
            border-color: transparent transparent $color-white;
          }

          &::after{
            position: absolute;
            bottom:5px;
            right:5px;
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            background-image: url(/images/loan/loan/icon-search.png);
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
      }
    }

    &-title{
      padding: 10px 20px;
    }

    &-contents{
      padding: 20px 20px 10px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

    }

  }

  &-topics{

    &-list{
      display: flex;
      align-items: center;
      justify-content: center;
      margin: -15px;

      @include view-at(tab){
        flex-wrap: wrap;
      }

      &-item{
        padding: 15px;
        width:100%;

        @include view-at(tab){
          width: 50%;
        }



        a{
          display: block;
          text-align: center;
          width:100%;
          background: $color-white;
          background-size: cover;
          border-radius: 50%;
          height: 255px;

          @include view-at(tab){
            height: 310px
          }

          @include view-at(sp){
            height: 150px
          }

        }
      }

    }

    &-contents{
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 255px;

      @include view-at(tab){
        height: 310px;
      }
      @include view-at(sp){
        height: 150px
      }
    }
  }

  &-outline {
    &-trigger {
      position: fixed;
      bottom: 24px;
      right: 24px;
      display: block;
      width: 74px;
      height: 73px;
      text-decoration: none;
      color: $color-white;
      text-align: center;
      padding: 19px 23px 5px;
      font-size: rem(13px);
      background-color: $color-blue;
      z-index: 1000;

      &:hover {
        opacity: 1;
      }

      @include view-at(pc) {
        &::before {
          content: '\e902';
          font-family: 'fa';
          font-size: rem(24px);
          letter-spacing: 0;
          margin-bottom: 5px;
          display: inline-block;
        }
      }

      @include view-at(sp) {
        padding: 32px 13px 4px;
        width: 52px;
        height: 52px;
        bottom: auto;
        right: 15px;
        top: 62px;
        font-size: rem(12px);

        &::before {
          content: '';
          display: block;
          position: absolute;
          width: 20px;
          height: 2px;
          top: 17px;
          left: 16px;
          background-color: $color-white;
          transition: transform .3s;
        }

        &::after {
          content: '';
          display: block;
          position: absolute;
          width: 2px;
          height: 20px;
          top: 8px;
          left: 25px;
          background-color: $color-white;
          transition: transform .3s;
        }

        &.active {
          &::before {
            transform: rotate(-45deg);
          }

          &::after {
            transform: rotate(-45deg);
          }
        }
      }
    }

    &-container {
      background-color: rgba(255,255,255,.5);
      position: fixed;
      top: 158px;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 100;
      transform: scale(0);
      transform-origin: calc(100% - 24px) calc(100% - 24px);
      transition: all .3s;

      @include view-at(sp) {
        top: 62px;
        right: 15px;
        left: 15px;
        bottom: auto;
        transform-origin: right top;
      }

      &.visible {
        transform: scale(1);
      }
    }

    &-contents {
      padding: 35px 25px;
      background-color: $color-blue;
      width: 318px;
      height: 100%;
      margin: 0 0 0 auto;

      @include view-at(sp) {
        padding: 30px 25px;
        width: 100%;
      }
    }

    &-list {
      list-style: none;
      margin: 0;
      padding: 0;

      >li {
        margin-bottom: 1em;

        &:last-child {
          margin-bottom: 0;
        }

        a {
          color: $color-white;
          text-decoration: none;
          font-size: rem(16px);
        }
      }
    }

    &-sublist {
      list-style: none;
      margin: 0;
      padding: 0;

      >li {
        margin-top: 1em;

        a {
          color: $color-white;
          text-decoration: none;
          font-size: rem(15px);
        }
      }
    }
  }

  &-column {
    &-merit {
      background-color: $color-white;
      border-radius: 50%;
      width: 200px;
      height: 200px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      padding-bottom: 30px;

      @include view-at(sp) {
        width: 150px;
        height: 150px;
        padding-bottom: 22px;
      }

      &-list {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin: 20px -20px;

        @include view-at(sp) {
          flex-wrap: wrap;
          margin: 10px -10px;
        }

        &-item {
          width: 240px;
          padding: 20px;

          @include view-at(sp) {
            width: 170px;
            padding: 10px;
          }
        }
      }

      p {
        font-weight: $bold;
        text-align: center;
        color: $color-blue;
        margin: 10px 0 0;

        @include view-at(sp) {
          font-size: rem(14px);
        }
      }

      img {
        @include view-at(sp) {
          height: 50px;
        }
      }
    }
  }
  &-faq {
    &-list {
      padding: 50px 0 80px;
      margin: -10px 0;

      &-item {
        padding: 10px 0;
      }
    }
    &-question {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 25px;
      background: $color-white;
      color: $color-black;
      font-weight: $bold;
      position: relative;

      @include view-at(sp) {
        padding: 15px 60px 15px 20px;
      }

      &:hover {
        opacity: 1;
      }

      &::before{
        content:'';
        display: block;
        position: absolute;
        top:calc(50% - 20px);
        right:25px;
        width:40px;
        height: 40px;
        background: $color-blue-light;
        border-radius: 50%;
        margin-left: 10px;

        @include view-at(sp) {
          top:calc(50% - 15px);
          width: 30px;
          height: 30px;
          right: 15px;
        }
      }

      &::after {
        content: '\e901';
        font-family: 'fa';
        font-weight: 300;
        display: block;
        position: absolute;
        top:calc(50% - 20px);
        right: 25px;
        width: 40px;
        height: 40px;
        font-size: rem(30px);
        color:$color-blue-dark;
        text-align: center;
        line-height: 40px;
        letter-spacing: 0;

        @include view-at(sp) {
          top:calc(50% - 15px);
          width: 30px;
          height: 30px;
          right: 15px;
          font-size: rem(20px);
          line-height: 30px;
        }
      }

      &.active{
        &::after{
          content: '\e900';
        }
      }

      &-text {
        display: flex;
        align-items: center;
      }
      &-icon {
        font-size: 24px;
        padding-right: 20px;
        color: $color-blue;
      }
    }
    &-answer {
      display: none;
      &.active {
        display: block;
      }
      &-contents {
        padding: 25px;
        background: $color-blue;
        color: $color-white;
        display: flex;
        align-items: center;
        @include view-at(sp) {
          padding: 20px;
        }

        a {
          color: #fff;
          text-decoration: underline;
        }
      }
      &-icon {
        font-size: 24px;
        padding-right: 20px;
      }
    }
    &-buuton {
      display: inline-block;
      width: auto;
      padding: 17px 20px;
      cursor: pointer;
      text-decoration: none;
      text-align: center;
      color: $color-blue;
      position: relative;
      line-height: 1;
      transition: color 0.3s, background-color 0.3s;
      font-size: rem(15px);
      min-width: 250px;
    }
  }
}