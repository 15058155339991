
/* --------------------------
color
-------------------------- */

$color-text: #333333;
$color-text-light: #B1B1B1;

$color-red: #DC3B3B;
$color-red-dark: #CB2D0D;
$color-red-light: #f8ebed;
$color-blue: #00A0E9;
$color-blue-dark: #096DAE;
$color-navy: #093361;
$color-blue-light: #E5F5FF;
$color-orange: #f19149;
$color-green: #82cf4f;
$color-green-light: #f2faed;
$color-green-dark: #4e8826;
$color-white:#fff;
$color-black: #333333;

$color-gray: #ccc;
$color-gray-light: #eee;
$color-gray-dark: #484B44;
$color-gray-snow: #F5F5F5;
$color-border: #707070;

/* --------------------------
spacing
-------------------------- */
$space: 80px, 80px, 40px, 20px;
$space-tab: 80px, 80px, 40px, 15px;
$space-sp: 40px, 40px, 35px, 10px;

/* --------------------------
font
-------------------------- */
$font-size: 3.33rem, 2rem, 1.6rem, 1.2rem, 1rem, 0.875rem;
$line-height: 1.5, 1.5, 1.5, 1.5, 1.5, 1.5;

$font-size-sp: 3.33rem, 2rem, 1.6rem, 1.2rem, 1rem, 0.875rem;
$line-height-sp: 1.5, 1.5, 1.5, 1.5, 1.5, 1.5;

$bold: 700;
$medium: 500;
$regular: 400;
$light: 300;
$normal: 400;

/* --------------------------
contents width
-------------------------- */
$contents-width: 1170px;
$contents-middle-width: 1040px;
$contents-narrow-width: 980px;
$contents-padding: 30px;
$contents-padding-sp: 20px;

/* --------------------------
z-index order
-------------------------- */
$z: (
  contents,
  arrow,
  gnav,
  leftbar,
  menu,
  header,
  footer,
  modal
);
