.buyer{
  &-accent-color{
    color:$color-blue-dark;
  }

  &-rate{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -15px -40px;

    @include view-at(sp) {
      margin: -15px;
    }

    &-title {
      @include view-at(sp) {
        font-size: rem(20px) !important;
      }
    }

    &-item{
      padding: 15px 40px;

      @include view-at(sp) {
        padding: 15px;
      }
    }
  }

  &-cta-rate {
    line-height: 1;
  }
}