.p-cta-loan {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 35px 30px;
  border: 0.5px solid #707070;

  &--blue{
    background: $color-blue-light;
  }

  &-title{
    font-size: rem(20px);
    line-height: rem(24px);
    letter-spacing: 0.05em;
    font-weight: $bold;

    @include view-at(tab) {
      margin-bottom: 5px;
      text-align: left;
    }
  }

  &-tel{
    display: flex;
    align-items: center;
    @include view-at(sp){
      flex-direction: column;
    }
  }

  &-number {
    font-size: rem(43px);
    font-weight: $bold;
    margin-top: 1px;
    letter-spacing: 0.09em;
    margin-left: 1px;

    @include view-at(tab) {
      font-size: rem(24px);
    }
  }
  
  &-text {
    padding-left:20px;
    line-height: 1.5;
    font-size: rem(13px);
    font-weight: $medium;
    text-align: left;
    padding-right: 0.5rem;

    @include view-at(tab) {
      text-align: center;
    }
  }
}