/* --------------------------
エクストラナビ（Gナビとは別にヘッダー内に表示するナビゲーション）
-------------------------- */

.p-exnav {
  display: flex;
  justify-content: flex-end;
  margin: 11px 0;

  @include view-at(tab) {
    display: none;
  }

  >li {
    margin-left: 18px;

    >a {
      color: $color-text;
      font-size: rem(12px);
      line-height: rem(20px);
      font-weight: $bold;
      text-decoration: none;

      &::after {
        content: '＞';
        display: inline-block;
        margin-left: 2px;
        font-size: rem(12px);
        position: relative;
        top: 0.5px;
        line-height: 1;
      }

      &.no-arrow {
        &::after {
          display: none;
        }
      }
    }
  }

  &-language {
    position: relative;

    &::before {
      content: '|';
      display: inline-block;
      padding-right: 18px;
      line-height: 20px;
      font-size: rem(12px);
    }

    &-icon {
      width: 13px;
      vertical-align: middle;
    }

    &-list {
      display: block;
      width: 85px;
      background-color: $color-white;
      padding: 6px 17px;
      position: absolute;
      top: 20px;
      left: 0;
      z-index: 100;
      transition: opacity 0.3s, visibility 0s 0.3s;
      opacity: 0;
      visibility: hidden;

      a {
        color: $color-text;
        font-size: rem(11px);
        line-height: rem(22px);
        font-weight: $bold;
        text-decoration: none;
      }
    }

    &:hover {
      .p-exnav-language-list {
        visibility: visible;
        opacity: 1;
        transition-delay: 0s;
      }
    }
  }
}
