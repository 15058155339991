.about {
  &-title{
    font-size:24px;
    line-height: 41px;
    letter-spacing: .3rem;
    margin-bottom: 28px;
    @include view-at(sp){
      margin-bottom: 0;
    }
  }
  &-panel{
    background-color: $color-white;
    box-shadow: 0 3px 6px rgba(0,0,0,0.31);
    &-contents{
      padding: 60px 65px;
      @include view-at(sp) {
        padding: 20px;
      }
    }
  }
  &-greenting{
    &-item{
      font-size: rem(16px);
      line-height: rem(20px);
      display: table;
      text-align: left;
      margin: 40px 0 0 auto;

      &--name{
        font-size: 1.25rem;
        line-height: 1.6875rem;
        font-weight: $bold;
        margin-bottom: 10px;

        @include view-at(sp){
          font-size: 1rem;
          line-height: 1.5;
        }
      }
    }
  }

  &-link{
    font-size:15px;
    position: relative;
    padding-right: 15px;
    display: inline-block;
    color:$color-black;
    &::after {
        content: '';
        width: 8px;
        height: 8px;
        border-top: 2px solid $color-blue;
        border-right: 2px solid $color-blue;
        transform: rotate(45deg);
        display: inline-block;
        position: absolute;
        top: calc(50% - 4px);
        right: 2px;
        transition: all 0.3s;
      }
  
      &:hover {
        opacity: 1;
  
        &::after {
          transform: rotate(45deg) translate(2px, -2px);
        }
    }
  }
  &-h{
    font-size:1.125rem;
    line-height: 1.6875rem;
    font-weight: $bold;
    color:$color-blue-dark;
    @include view-at(sp){
      font-size: 1rem;
      line-height: 1.5625rem;
    }
    span{
      font-size: 1.75rem;
      line-height: 2.625rem;
    }
  }
  .c-table{
    tbody{
      letter-spacing: 0;

      tr{
        th,
        td {
          padding: 10px 15px;
          line-height: rem(22px);
        }

        th {
          padding-left: 0;
        }
        @include view-at(sp){
          display: inline-block;
          width: 100%;
          th{
            display: inline-block;
            width: 100%;
            padding: 5px 0;
            font-weight: $bold;
          }
          td{
            display: inline-block;
            width: 100%;
            padding: 0 0 20px;
          }
        }
      }
    }
    
    &.c-table--border{
      tbody tr{
        &:last-child{
          border-bottom:none;
        }
      }
    }
  }

  &-list {
    margin-bottom: 61px;
    padding-left: 22px;
    
    li {
      margin-bottom: 0;
      line-height: rem(26px);
    }
  }
}