@charset "UTF-8";

.member{
  &-list{
    display: flex;
    justify-content: space-between;
    margin: -15px;

    @include view-at(sp){
      display: block;
      margin: 0;
    }

    &-item{
      padding: 15px;

      a{
        display: block;
        width: 100%;
        position: relative;

        &::after{
          content: '';
          display: inline-block;
          width:18px;
          height: 18px;
          border-right:2px solid $color-blue-dark;
          border-bottom:2px solid $color-blue-dark;
          transform: rotate(45deg);
          position: absolute;
          bottom:15px;
          left:calc(50% - 9px);
          transition: all .3s;
          }

        &:hover{
          &::after{
            border-color:$color-blue-dark;
            transform: rotate(45deg) translate(2px, 2px);
          }
        }
      }

    }

  }

  &-panel{
    background: $color-white;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.31);

    &-contents{
      padding: 40px 25px 45px;
      position: relative;

      &-copy{
        color:$color-black;
        font-weight: $bold;
        margin-bottom: 0;
      }

      &-label{
        background: linear-gradient(90deg, #008ddd 0%, #004d8f 100%);
        padding: 7px 25px;
        color:$color-white;
        display: inline-block;

        &-wrapper{
          position: absolute;
          left:0;
          top:-15px;
        }

      }
    }
  }

  &-interview{
    &-section{
      padding: 55px 0 41px;
    }

    &-h{
      font-size:24px;
      line-height: 1.5;
      letter-spacing: .15em;
      margin-bottom: 1rem;
      font-weight: $bold;
    }

    &-photo{
      float: right;
      width:440px;
      padding-left: 30px;
      padding-bottom: 25px;

      @include view-at(tab){
        width:50%;
      }

      @include view-at(sp){
        float: none;
        width: 100%;
        padding-left: 0;
        padding-bottom: 0;
      }

      img{
        width:100%;
      }
    }

    &-panel{
      background: $color-blue-light;
      padding: 46px 0 65px;

      &-title{
        font-size:20px;
        line-height: 1.8;
        letter-spacing: .15em;
        color:$color-blue-dark;
        border-bottom:1px solid $color-blue;
        padding-bottom: 2px;
        margin-bottom: 15px;
        font-weight: $bold;

        &--right{
          margin-right: 415px;

          @include view-at(tab){
            margin-right: 0;
          }
        }

      }

    }

  }

}