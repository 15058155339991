.overseas{
  .c-table--border{
    tbody{
        th{
          font-weight: $bold;
          &.u-text-accent{
            color:$color-blue-dark;
          }
        }
        td{
          line-height: 1.5;
          vertical-align: top;
          &.u-text-accent{
            color:$color-blue-dark;
          }
        }
    } 
  }
  .c-panel{
    @include view-at(sp){
      margin: 0 -20px;
    }
  }

  &-cta-contact{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 35px 30px;
    border: 0.5px solid #707070;
    &--blue{
      background: $color-blue-light;
    }
    &-title{
      font-size: rem(20px);
      line-height: rem(24px);
      letter-spacing: 0.05em;
      font-weight: $bold;

      @include view-at(tab) {
        margin-bottom: 5px;
        text-align: left;
      }
    }
    &-tel{
      display: flex;
      align-items: center;
      @include view-at(sp){
        flex-direction: column;
      }
    }
    &-number {
      font-size: rem(43px);
      font-weight: $bold;
      margin-top: 1px;
      letter-spacing: 0.09em;
      margin-left: 1px;

      @include view-at(tab) {
        font-size: rem(24px);
      }
    }
    &-text {
      padding-left:20px;
      line-height: 1.5;
      font-size: rem(13px);
      font-weight: $medium;
      text-align: left;
      padding-right: 0.5rem;

      @include view-at(tab) {
        text-align: center;
      }
    }
  }

  &-intro{
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include view-at(sp){
      flex-direction: column;
      justify-content: center;
    }
    &-image{
      width: 30%;
      @include view-at(sp){
        width:100%;
      }
      img{
        width:100%;
        display: block;
      }
    }
    &-detail{
      width:70%;
      background: linear-gradient(90deg, #008ddd 0%, #004d8f 100%);
      padding: 40px;
      color:$color-white;
      align-self: stretch;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include view-at(sp){
        width: 100%;
        padding: 20px;
      }
    }
  }
  &-casestudy{
    position: relative;
    top:-80px;
    z-index: -1;
    @include view-at(sp){
      width: 100%;
      top:-40px;
      &.c-panel{
        margin: 0;
      }
    }
    &-list{
      padding: 0 15px;
      >li {
        margin-bottom: 1em; 
        line-height: 1.5;
        padding-left: 15px;
        position: relative;
        &::before {
          content: '';
          width: 14px;
          height: 14px;
          border-radius: 50%;
          display: block;
          background-color: $color-blue-dark;
          position: absolute;
          top: 6.5px;
          left: 0;
        }
      }
    }
  }
}