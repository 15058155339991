.agent{
  &-accent-color{
    color:$color-blue-dark;
  }

  &-border{
    border:30px solid;

    &--blue{
      border-color:$color-blue-light
    }

    &--white{
      border-color:$color-white;
    }
  }

  &-mv{
    background-image:url(/images/loan/realestate-business/mv.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    max-height: 860px;
    height: 66vh;

    @include view-at(sp){
      max-height: 550px;
    }

    &-title{
      font-size:45px;
      line-height: 1.4;
      font-weight: $bold;
      margin-bottom: 25px;

      @include view-at(sp) {
        font-size: rem(38px);
      }
    }

    &-description{
      font-size: rem(22px);

      @include view-at(sp) {
        font-size: rem(18px);
        font-weight: $bold;
      }
    }

    &-contents{
      display: flex;
      max-height: 860px;
      height: 66vh;
      justify-content: flex-start;
      align-items: center;
      color:$color-white;
      text-shadow: 0 0 6px $color-black;


      @include view-at(sp){
        max-height: 550px;
      }
    }

  }

  &-list{
    display: flex;
    margin: -15px;

    @include view-at(tab){
      flex-wrap: wrap;
      justify-content: center;
    }

    &-item{
      padding: 15px;
      width:100%;

      @include view-at(tab){
        width:50%;
      }

      @include view-at(sp){
        width:100%;
      }

      a{
        display: block;
        width:100%;
        background: url(/images/loan/realestate-business/panel-link.png);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        border:1px solid $color-gray;
        padding: 45px 30px 20px;
      }

    }

    &-label{
      font-size:14px;
      color:$color-white;
      padding: 8px 10px;
      background: $color-blue;
      border-radius: 5px;
      display: inline-block;
      margin-top: 15px;
    }

    &-link{
      margin-top: 30px;
      font-size:15px;
      position: relative;
      padding-right: 15px;
      display: inline-block;
      border-bottom:1px solid $color-blue;
      padding-bottom:5px;

      &::after {
        content: '';
        width: 8px;
        height: 8px;
        border-top: 2px solid $color-blue;
        border-right: 2px solid $color-blue;
        transform: rotate(45deg);
        display: inline-block;
        position: absolute;
        top: calc(50% - 4px);
        right: 2px;
        transition: all 0.3s;
      }

      &:hover {
          opacity: 1;

          &::after {
            transform: rotate(45deg) translate(2px, -2px);
          }

        }

      }
  }

  &-feature{

    &-title{
      font-weight: $bold;
      line-height: 2.2!important;
      margin: 30px 0;

      @include view-at(sp) {
        font-size: rem(26px) !important;
      }
    }

    &-list{
      display: flex;
      align-items: center;
      justify-content: center;
      margin: -30px;

      @include view-at(tab){
        flex-wrap: wrap;
      }

      @include view-at(sp){
        margin: -30px;
      }

      &-item{
        padding: 30px;
        width: 100%;

        @include view-at(tab){
          width:50%;
        }

        @include view-at(sp){
          width:100%;
          padding: 30px;
        }
      }

    }

    &-contents{
      background: $color-white;
      background-size: cover;
      height: 100%;
      border-radius: 50%;
      height: 330px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      @include view-at(tab){
        height: 295px;
      }

      @include view-at(sp){
        height: 275px;
      }
    }

  }

  &-topics{

    &-list{
      display: flex;
      align-items: center;
      justify-content: center;
      margin: -15px;

      @include view-at(tab){
        flex-wrap: wrap;
      }

      &-item{
        padding: 15px;
        width:100%;

        @include view-at(tab){
          width: 50%;
        }

        @include view-at(sp){
          width: 100%;
        }

        a{
          display: block;
          text-align: center;
          width:100%;
          background: $color-white;
          background-size: cover;
          border-radius: 50%;
          height: 255px;

          @include view-at(tab){
            height: 310px
          }

          @include view-at(sp){
            height: 275px
          }

        }
      }

    }

    &-contents{
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 255px;

      @include view-at(tab){
        height: 310px;
      }
      @include view-at(sp){
        height: 275px
      }
    }
  }

}