/* --------------------------
ラベル
-------------------------- */

.c-label {
  display: inline-block;
  padding: 8px 0;
  text-align: center;
  width: 100px;
  font-size: rem(14px);
  line-height: 1;
  vertical-align: bottom;
  background-color: $color-gray;
  color: $color-text;
  font-weight: $bold;

  &--important {
    background-color: $color-blue-dark;
    color: $color-white;
  }

  &--news {
    background-color: $color-blue;
    color: $color-white;
  }

  &--campaign {
    background-color: $color-blue;
    color: $color-white;
  }

  &--seminar {
    background-color: $color-blue;
    color: $color-white;
  }
}
