.news {
  &-archive {
    &-list {
      display: flex;
      justify-content: center;
      margin: -10px;

      @include view-at(sp) {
        flex-wrap: wrap;
      }

      li {
        padding: 10px;
      }
    }
  }

  &-header {
    display: flex;
    align-items: center;

    @include view-at(sp) {
      display: block;
    }

    &-line {
      flex-grow: 1;
      height: 0;
      border: 1px solid $color-blue;
      min-width: 100px;

      @include view-at(sp) {
        margin: 10px 0;
      }
    }
  }

  &-title {
    font-size: rem(31px);
    font-weight: $bold;
    padding-right: 15px;
  }

  &-meta {
    display: flex;
    align-items: center;

    @include view-at(sp) {
      justify-content: flex-end;
    }
  }

  &-date {
    font-size: rem(20px);
    padding: 0 12px 0 15px;
  }

  &-body {
    line-height: 1.5em;

    h1 {
      font-size: rem(24px);
      font-weight: $bold;
      margin-bottom: 1em;
    }

    h2 {
      font-size: rem(20px);
      font-weight: $bold;
      margin-bottom: 1em;
    }

    h3 {
      font-size: rem(18px);
      font-weight: $bold;
      margin-bottom: 1em;
    }

    h4 {
      font-size: rem(16px);
      font-weight: $bold;
      margin-bottom: 1em;
    }

    p {
      margin-top: 1em;
    }

    ul {
      @extend .c-list;
      @extend .c-list--circle;
    }

    ol {
      @extend .c-list;
      @extend .c-list--order;
    }

    table {
      @extend .c-table;
      @extend .c-table--border;
    }
  }
}