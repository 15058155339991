.p-news {
    li {
        margin-bottom: 20px; 
        padding-left: 20px;
        padding-bottom: 20px;
        position: relative;
        border-bottom:1px solid $color-blue;
        @include view-at(sp){
          padding-bottom: 10px;
        }

      &::before {
        content: '';
        width: 8px;
        height: 8px;
        border-top: 1px solid $color-black;
        border-right: 1px solid $color-black;
        transform: rotate(45deg);
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -14px;

        @include view-at(sp) {
          top: 12px;
          margin-top: 0;
        }
      }
      a{
          color:$color-black;
          display: flex;
          align-items: center;
          line-height: 23px;

          @include view-at(sp) {
            flex-wrap: wrap;
          }
      }
    }
    &-date{
        padding-right:15px;
        display: inline-block;
        width: 120px;
        flex-shrink: 0;
    }

    &-label {
      flex-shrink: 0;
    }
    
    &-description{
        text-decoration: underline;
        margin-left: 15px;

        @include view-at(sp) {
          margin-left: 0;
        }
        
        &--pdf {
            &::after {
              display: inline-block;
              content: '';
              width: 1.2em;
              height: 1.2em;
              background: transparent url(/images/common/icon-pdf.svg) center center no-repeat;
              background-size: contain;
              margin-left: 8px;
              transform: translateY(3px);
            }
        }
        &--external {
            &::after {
              display: inline-block;
              content: '';
              width: 1.2em;
              height: 1.2em;
              background: transparent url(/images/common/icon-external.svg) center center no-repeat;
              background-size: contain;
              margin-left: 8px;
              transform: translateY(3px);
            }
        }
    }
}