.p-financing_case{
  &-list{
    margin: -15px;
    display: flex;
    justify-content: space-between;

    @include view-at(tab){
      flex-wrap: wrap;
    }

    &-item{
      padding: 15px;
      width: 100%;

      @include view-at(tab){
        width: 50%;
      }

      @include view-at(sp){
        width: 100%;
      }

      a{
        display: block;
        height: 100%;
        text-align: center;
        color:$color-white;
        line-height: 1.6;
        position: relative;


        &::before{
          position: absolute;
          top:calc(50% - 4px);
          left: 15px;
          content: '';
          width: 8px;
          height: 8px;
          border-top:2px solid $color-white;
          border-right:2px solid $color-white;
          transform: rotate(45deg);
        }
      }

    }

  }

  &-contents{
    padding: 45px 15px;
    background: linear-gradient(90deg, #008ddd 0%, #004d8f 100%);
    width:100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @include view-at(tab){
      padding: 45px 10px;
    }

  }

}

