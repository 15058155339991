.u-overflow {
    &-scrollX {
        width: 100%;
        overflow-x: auto;
        /* IE, Edge 対応 */
        -ms-overflow-style: none;

        img {
            max-width: inherit;
        }
    }
}