.p-migrate-nav {
  margin-bottom: 30px;

  &-list {
    display: flex;
    margin: -15px;

    @include view-at(tab) {
      flex-wrap: wrap;
      margin: -7.5px;
    }

    li {
      padding: 15px;
      width: 100%;

      @include view-at(tab) {
        padding: 7.5px;
      }

      a {
        display: block;
        background: radial-gradient(ellipse farthest-side at top 15% left 35%, #6dc4f2 0%, #005cad 100%);
        color: $color-white;
        text-align: center;
        padding: 30px 20px;

        @include view-at(tab) {
          padding: 22px 10px;
        }

        .inner {
          position: relative;
          display: inline-block;
          padding-left: 40px;
          font-size: rem(15px);
          line-height: rem(24px);
          font-weight: $bold;
          text-align: left;

          @include view-at(tab) {
            font-size: rem(14px);
            padding-left: 33px;
          }

          &::before{
            content: '';
            position: absolute;
            top: 11px;
            left: 0;
            background: $color-white;
            border-radius: 50%;
            width: 25px;
            height: 25px;

            @include view-at(tab) {
              top: 0;
            }
          }
          &::after{
            content: '';
            width: 8px;
            height: 8px;
            border-top:2px solid $color-blue;
            border-right: 2px solid $color-blue;
            transform: rotate(45deg);
            display: inline-block;
            position: absolute;
            top: 20px;
            left: 7px;

            @include view-at(tab) {
              top: 9px;
            }
          }
        }
      }
    }
  }
}